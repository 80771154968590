import React, { useEffect, useState } from "react";
import Table from "../../Table";
import { slugify } from "../../../utils";

const EnquiryReportTable = ({ data, corporateAccount }) => {
  const [tableHeaders, setTableHeaders] = useState(defaultHeaders);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    let headers = [];
    if (corporateAccount && corporateAccount.custom_field_questions !== null) {
      const customFields = JSON.parse(corporateAccount.custom_field_questions);
      if (customFields.length > 0) {
        customFields.map(field => {
          if (!headers.some(header => header.key === slugify(field.fieldIdentifier))) {
            headers.push({
              key: slugify(field.fieldIdentifier),
              display: field.label,
            });
          }
        });
      }
      setTableHeaders([...tableHeaders, ...headers]);
      data.map((row) => {
        row.custom_fields.map((field) => {
          let fieldValue;
          switch (field.field_identifier) {
            case "text1":
              fieldValue = field.value;
              break;
            case "dropdown1":
              fieldValue = field.value;
              break;
            case "checkbox1":
              if (typeof field.checkbox_value === "boolean") {
                fieldValue = field.checkbox_value ? "True" : "False";
              } else {
                fieldValue = field.checkbox_value;
              }
              break;
            case "date1":
              fieldValue = field.date_value ? field.date_value : "";
              break;
            case "textarea1":
              fieldValue = field.text_area_value;
              break;
            default:
              fieldValue = field.value;
          }
          delete row.custom_fields;
          let key = slugify(field.field_identifier);
          row[key] = fieldValue;
        });
      });
    }

    setTableData(data);
  }, []);

  return (
    <Table
      admin="min-w-4"
      tableClass="text-xs"
      headers={tableHeaders.map(header => (header.nosort || typeof header === "string")
        ? header : {
          key: header.key,
          display: header.display,
          class: typeof header.class !== "undefined" ? header.class : " ",
        },
      )}
      data={tableData}
    />
  );
};

const defaultHeaders = [
  {
    key: "no_of_meetings",
    display: "Active/Cancelled",
  },
  {
    key: "enquiry_number",
    display: "Enquiry Number",
  },
  {
    key: "enquiry_date",
    display: "Enquiry Date",
  },
  {
    key: "event_date",
    display: "Event Date",
  },
  {
    key: "month",
    display: "Month",
  },
  {
    key: "no_of_days",
    display: "Number of Days",
  },
  {
    key: "no_of_nights",
    display: "Number of Nights",
  },
  {
    key: "event_name",
    display: "Event Name",
    class: "min-w-48",
  },
  {
    key: "department",
    display: "Department",
    class: "min-w-48",
  },
  {
    key: "sub_division",
    display: "Sub Division",
  },
  {
    key: "hotel",
    display: "Hotel",
    class: "min-w-48",
  },
  {
    key: "hotel_chain",
    display: "Hotel Chain",
    class: "min-w-48",
  },
  {
    key: "city",
    display: "City",
  },
  {
    key: "country",
    display: "Country",
  },
  {
    key: "client_name",
    display: "Client Name",
  },
  {
    key: "original_client_name",
    display: "Original Client Name",
  },
  {
    key: "contact_on_day",
    display: "Contact on Day",
    class: "min-w-48",
  },
  {
    key: "total_pax",
    display: "Total Pax",
  },
  {
    key: "final_numbers",
    display: "Final Numbers",
  },
  {
    key: "ddr_ex_vat",
    display: "DDR (ex VAT)",
  },
  {
    key: "b_and_b_ex_vat",
    display: "B&B (ex VAT)",
  },
  {
    key: "24hr_ex_vat",
    display: "24Hr (ex VAT)",
  },
  {
    key: "dinner_per_head",
    display: "Dinner Per Head",
  },
  {
    key: "room_hire_ex_vat",
    display: "Room Hire (ex VAT)",
  },
  {
    key: "net_total",
    display: "Net Total",
  },
  {
    key: "net_cost_savings",
    display: "Net Cost Savings",
  },
  {
    key: "gross_total",
    display: "Gross Total",
  },
  {
    key: "gross_cost_savings",
    display: "Gross Cost Savings",
  },
  {
    key: "reason_for_cancellation",
    display: "Reason for Cancellation",
  },
  {
    key: "cancellation_fees",
    display: "Cancellation Fees",
  },
  {
    key: "status",
    display: "Status",
  },
  {
    key: "contract_signed",
    display: "Contract Signed",
  },
  {
    key: "foreign_currency",
    display: "Foreign Currency",
  },
];

export default EnquiryReportTable;