import React, {useEffect, useState} from "react";
import {currencyFormatter} from "../../utils";
import {
    prettifyInternationalPriceBandingTitle,
    prettifyPriceBandingTitle
} from "../../helpers/prettifyPriceBandingTitle";
import priceBanding from "../../pages/admin/EditVenue/ConferencePricing/PriceBanding";


let PriceBandingReduced = ({type, venuePriceBindings, typeClass, bandTitleClass, currencyClass}) => {
    let [packageBanding, setPackageBanding] = useState(false)
    useEffect(() => {
        setPackageBanding(venuePriceBindings[type === '24hr' || type === 'twenty_four_hr' ? 'twenty_four_hr_price_banding_rate' : type + '_price_banding_rate']);
    }, []);

    if(!packageBanding) {
        return <></>
    }
    return (
        <span
            className={`text-sm font-normal`}
        >
            <span className={typeClass}>{type.toUpperCase()}</span>: <span className={bandTitleClass}>{venuePriceBindings.is_international ? prettifyInternationalPriceBandingTitle(packageBanding) : prettifyPriceBandingTitle(packageBanding, '=')}</span>


        </span>
    )
}

export default PriceBandingReduced