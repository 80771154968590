import axios from "axios";
import {
  userId,
  userType,
  userTown,
  userIndustry,
  userCompany,
} from "../../auth";

const venueImpression = ({ id, data_score }) => {
  return async (d, getState) => {
    if (process.env.NODE_ENV === "development") return;
    const uuid = window.uuid;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ANALYTICS}/v2/venue_impressions`,
        {
          venue_id: id,
          data_score,
          uuid,
          user_id: userId(getState()) || undefined,
          user_company: userCompany(getState()) || undefined,
          user_type: userType(getState()) || undefined,
          user_town: userTown(getState()) || undefined,
          user_industry: userIndustry(getState()) || undefined,
          user_agent: navigator.userAgent || undefined,
        }
      );
      return response.data;
    } catch (e) {
      // die silently
      return null;
    }
  };
};

const venueInteraction = ({ id, type, impression_id, data_score }) => (
  d,
  getState
) => {
  if (process.env.NODE_ENV === "development") return;
  const uuid = window.uuid;
  axios
    .post(`${process.env.REACT_APP_ANALYTICS}/v2/venue_interactions`, {
      venue_id: id,
      impression_id,
      data_score,
      type,
      uuid,
      user_id: userId(getState()) || undefined,
      user_company: userCompany(getState()) || undefined,
      user_type: userType(getState()) || undefined,
      user_town: userTown(getState()) || undefined,
      user_industry: userIndustry(getState()) || undefined,
      user_agent: navigator.userAgent || undefined,
    })
    .catch(() => {
      // die silently
    });
};

const searchClickThrough = ({
  venue_id,
  payload_simple,
  payload_advanced,
  rank,
  data_score,
}) => (d, getState) => {
  if (process.env.NODE_ENV === "development") return;
  const uuid = window.uuid;
  axios
    .post(`${process.env.REACT_APP_ANALYTICS}/v2/search_clickthrough`, {
      venue_id,
      payload_simple,
      payload_advanced,
      rank,
      data_score,
      uuid,
      user_id: userId(getState()) || undefined,
      user_company: userCompany(getState()) || undefined,
      user_type: userType(getState()) || undefined,
      user_town: userTown(getState()) || undefined,
      user_industry: userIndustry(getState()) || undefined,
      user_agent: navigator.userAgent || undefined,
    })
    .catch(() => {
      // die silently
    });
};

/**
 *
 * @param {Array of {
  venue_id,
  payload_simple,
  payload_advanced,
  rank,
  data_score
}} impressions
 */
const searchImpressions = impressions => (d, getState) => {
  if (process.env.NODE_ENV === "development") return;
  const uuid = window.uuid;
  axios
    .post(
      `${process.env.REACT_APP_ANALYTICS}/v2/search_impressions`,
      impressions.map(data => ({
        ...data,
        uuid,
        user_id: userId(getState()) || undefined,
        user_company: userCompany(getState()) || undefined,
        user_type: userType(getState()) || undefined,
        user_town: userTown(getState()) || undefined,
        user_industry: userIndustry(getState()) || undefined,
        user_agent: navigator.userAgent || undefined,
      }))
    )
    .catch(() => {
      // die silently
    });
};

const generalInteraction = ({ type, data = undefined }) => (
  d,
  getState
) => {
  if (process.env.NODE_ENV === "development") return;
  const uuid = window.uuid;
  axios
    .post(`${process.env.REACT_APP_ANALYTICS}/v2/general_interactions`, {
      type,
      uuid,
      data,
      slug: window?.location?.pathname || undefined,
      user_id: userId(getState()) || undefined,
      user_company: userCompany(getState()) || undefined,
      user_type: userType(getState()) || undefined,
      user_town: userTown(getState()) || undefined,
      user_industry: userIndustry(getState()) || undefined,
      user_agent: navigator.userAgent || undefined,
    })
    .catch(() => {
      // die silently
    });
};

export {
  venueImpression,
  venueInteraction,
  searchClickThrough,
  searchImpressions,
  generalInteraction
};
