import moment from "moment";
import { ButtonPrimary } from "../../../../../Components/Button";
import axios from "axios";
import { saveData } from "../../../../../services/downloadFile";
import React, { useState } from "react";
import { token as tokenSelector } from "../../../../../modules/auth";
import { useSelector } from "react-redux";
import Loading from "../../../../../Components/Loading";
import { ENQUIRY_VENUE_DATA_STATUS } from "../../../../../modules/rfp/enquiryVenueDataStatus";

let DownloadPDFButton = ({
                           enquiry,
                           enquiryVenueData,
                           direction,
                           dark,
                           disableCommission = false,
                           text = "Download Confirmation (PDF)",
                           includeVat = false
                         }) => {
  const [submitting, setSubmitting] = useState(false);
  const [isRfpXpress, setIsRfpXpress] = useState(Boolean(enquiry?.brief?.rfp_xpress_service));
  const token = useSelector(tokenSelector);

  if (isRfpXpress && disableCommission) {
    if (includeVat) {
      text = "Export Gross as PDF without commission"
    } else {
      text = "Export Net as PDF without commission"
    }
  } else if (isRfpXpress && !disableCommission) {
    if (includeVat) {
      text = "Export Gross as PDF";
    } else {
      text = "Export Net as PDF"
    }
  }

  if (enquiryVenueData.status < ENQUIRY_VENUE_DATA_STATUS.VENUE_CONFIRMED) {
    return null;
  }

  const handleExport = (enquiryId, enquiryVenueDataId, name) => async () => {
    if (submitting) {
      return;
    }
    let url = `${window.API}/rfp/${enquiryId}/confirm/${enquiryVenueDataId}/export`;
    let queryParams = new URLSearchParams();
    if (disableCommission) {
      queryParams.set('disable_commission', 1)
    }
    if (includeVat) {
      queryParams.set('include_vat', 1); // if includeVat return gross
    }
    url += "?" + queryParams.toString();
    setSubmitting(true);
    try {
      const response = await axios.get(
        url,
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      saveData(
        false,
        response.data,
        `${name.replace(/\s/g, "-")}-${moment().format("DD-MM-YYYY")}-proposal-ref-${enquiryVenueDataId}-confirmation.pdf`,
      );
      setSubmitting(false);
    } catch (err) {
      setSubmitting(false);
      alert(`Sorry there was a problem fetching the pdf`);
    }
  };

  return (
    <>
      {submitting ? <Loading classes={"px-5"} large dark={dark} inline={true} />
        : <p className={`font-bold mb-0 ${direction === "col" ? "pt-5" : null} ${direction === "row" ? "pl-5" : null}`}>
          <ButtonPrimary
            onClick={handleExport(enquiry.id, enquiryVenueData.id, enquiry.brief ? enquiry.brief.event_name : enquiry.event_name)}>
            {text}
          </ButtonPrimary>
        </p>
      }
    </>
  );
};

export default DownloadPDFButton;
