import React, {Component} from "react";
import {Field, reduxForm} from "redux-form";
import { connect } from 'react-redux';
import Loading from "../Loading";
import {ButtonPrimary} from "../Button";
import {Select} from "../../ui";
import {objectDiff, processFormValues, renderFormInput} from "../../modules/formTools";
import {Failed, Succeeded} from "../Form";
import isArray from "lodash/isArray";
import {required} from "../../modules/validation";

const v = {
  required: required(),
};

const mapStateToProps = (state, props) => {
  let initialValues = {};

  if (Object.entries(props.company).length > 0) {
    initialValues = {...props.company, ...props.company.address};
  }
  if (props.agencyCode) {
    initialValues.agency_code = props.agencyCode
  }
  return {
    initialValues,
  };
};

class CompanyForm extends Component {
  onSubmit = formValues => {
    delete this.props.initialValues.agency_code;
    const formData = new FormData();
    if (this.props.dirty && (Object.keys(this.props.initialValues).length > 0)) {
      formData.append("_method", "PATCH");
      const data = objectDiff(this.props.initialValues, formValues)
      Object.entries(data).forEach(([key, value]) => {
        if (value && !isArray(value)) {
          formData.append(key, value)
        } else {
          if (value && value.length > 0) {
            formData.append(key, JSON.stringify(value))
          }
          if(key == 'address2' || key == 'address3')
          {
            formData.append(key, value)
          }
        }
      });
      return this.props.onSubmit(formData, this.props.token)
    } else {
      Object.entries(formValues).forEach(
          ([key, value]) => value && formData.append(key, value)
      );
      return this.props.onSubmit(formData, this.props.token);
    }

    return processFormValues(this.props, formValues, this.props.initialValues);
  }

  render() {
    const {company, countries, industries, bookingDesks, fullWidth = false} = this.props;
    const options = [];
    if ( Object.entries(countries).length > 0 ) {
      countries.sort((a, b) => a.name.localeCompare(b.name))
      countries.map((country) => {
        options.push({
          text: country.name,
          value: country.id
        })
      })
      options.forEach(function(country,i){
        if(country.text === "United Kingdom"){
          options.splice(i, 1);
          options.unshift(country);
        }
      });
    }
    let industriesOptions = [];

    let bookingDeskOptions = [];
    if (bookingDesks && Object.entries(bookingDesks).length > 0) {
      bookingDesks.map(desk => {
        bookingDeskOptions.push({
          text: desk.name,
          value: desk.id
        })
      })
    }

    if (industries && Object.entries(industries).length > 0) {
      industries.map(industry => {
        industriesOptions.push({
          text: industry.data.name,
          value: industry.data.id,
        })
      })
    }
    const rfpXpressOptions = [
      {
        text: "Yes",
        value: 1,
      },
      {
        text: "No",
        value: 0,
      }
    ];

    const ssoMethodOptions = [{
      text: "Token",
      value: "token"
    }];
    return (
      <div>
          <div className="overflow-hidden border-gray-200 sm:rounded-lg">
            <form className="border-none" onSubmit={this.props.handleSubmit(this.onSubmit)}>
              <div className={'row'}>
                <div className={`col col-w-full mb-6 ${fullWidth ? '' : 'md:col-w-1/2'}`}>
                  <Field name="name" label="Company Name" component={renderFormInput} disabled={this.props.isCompanyAdmin && (!this.props.isAgencyAdmin && !this.props.isRoleEdgeAdmin)} validate={v.required}/>
                  <Field name="address1" label="Address Line 1" component={renderFormInput} disabled={this.props.isCompanyAdmin && (!this.props.isAgencyAdmin && !this.props.isRoleEdgeAdmin)} validate={v.required}/>
                  <Field name="address2" label="Address Line 2" component={renderFormInput} disabled={this.props.isCompanyAdmin && (!this.props.isAgencyAdmin && !this.props.isRoleEdgeAdmin)}/>
                  <Field name="address3" label="Address Line 3" component={renderFormInput} disabled={this.props.isCompanyAdmin && (!this.props.isAgencyAdmin && !this.props.isRoleEdgeAdmin)}/>
                  <Field name="city" label="City" component={renderFormInput} disabled={this.props.isCompanyAdmin && (!this.props.isAgencyAdmin && !this.props.isRoleEdgeAdmin)} validate={v.required}/>
                  <Field name="county" label="County" component={renderFormInput} disabled={this.props.isCompanyAdmin && (!this.props.isAgencyAdmin && !this.props.isRoleEdgeAdmin)} validate={v.required}/>
                  <Field name="postcode" label="Postcode" component={renderFormInput} disabled={this.props.isCompanyAdmin && (!this.props.isAgencyAdmin && !this.props.isRoleEdgeAdmin)} validate={v.required}/>
                  <Field name="country_id" label="Country" component={Select} options={options} disabled={this.props.isCompanyAdmin && (!this.props.isAgencyAdmin && !this.props.isRoleEdgeAdmin)} validate={v.required} />
                  <Field name="industry_id" label="Industry Sector" component={Select} options={industriesOptions} disabled={this.props.isCompanyAdmin && (!this.props.isAgencyAdmin && !this.props.isRoleEdgeAdmin)} validate={v.required}/>
                  <Field name="default_booking_desk_id" label="Default Booking Desk" component={Select} options={bookingDeskOptions} />
                  {company && company.corporate_account && company.sso_method !== 'saml2' ? (
                    <Field name="sso_method" label="SSO Method" component={Select} options={ssoMethodOptions} />
                  ) : null}

                  {this.props.company.agency && Boolean(this.props.company.agency.offer_rfp_xpress_service) ? (
                    <Field name="offer_rfp_xpress_service" label="Express 2 Hour SLA Service?" component={Select} options={rfpXpressOptions} noPlaceholder={true}/>
                  ) : null}

                  {this.props.isCompanyAdmin && (!this.props.isAgencyAdmin && !this.props.isRoleEdgeAdmin) && this.props.company.agency ?
                    <p>To edit this part of your profile, please contact {this.props.company.agency.name} via {this.props.company.agency.email_address} / {this.props.company.agency.phone_number}</p> :
                    <ButtonPrimary type="submit">
                      {this.props.submitting ? <Loading inline/> : "Save"}
                    </ButtonPrimary>
                  }
                  {this.props.submitSucceeded && !this.props.company && !this.props.submitError && <Succeeded text="Company created successfully"/>}
                  {this.props.submitSucceeded && this.props.company && !this.props.submitError && <Succeeded />}
                  {(this.props.company && this.props.company.error) || this.props.submitError && <Failed leftAlign/>}
                </div>
              </div>
            </form>
          </div>
      </div>
    )
  }
}

//const validate = {
//  name: required("Please enter a Company Name"),
//  code: required("Please enter a Company Code"),
//  address1: required("Please enter Address Line 1"),
//  address2: required("Please enter Address Line 2"),
//  city: required("Please enter City"),
//  county: required("Please enter County"),
//  postcode: required("Please enter Postcode"),
//  country_id: required("Please choose a country")
//};

CompanyForm = reduxForm({
  form: 'companyForm',
  enableReinitialize: true,
  destroyOnUnmount: true,
})(CompanyForm);

export default connect(mapStateToProps,null)(CompanyForm);
