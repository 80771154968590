import React, {lazy, Suspense} from "react";
import {Redirect, Switch, Route, withRouter, useHistory} from "react-router-dom";
import {connect} from "react-redux";
import {bool, object} from "prop-types";
import {isLoggedIn, isVenueAdmin, isAgent, isAgencyApproved, isCorporate} from "modules/auth";
import {refreshUser, refreshUserVenues, refreshUserCompany} from "modules/auth-thunks";

import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

import AdminHeader from "Components/Layout/AdminHeader";
import Footer from "Components/Layout/Footer";
import NotFound from "pages/NotFound";
import Dashboard from "Components/Dashboard";
import Profile from "pages/admin/Profile";
import CorpDashboard from "pages/corporate/CorpDashboard";

import { generalInteraction } from "../modules/analytics";

/* Users */
import Shortlists from "pages/admin/my-edge/Shortlists";
import SavedSearches from "pages/admin/my-edge/SavedSearches";
import Notes from "pages/admin/my-edge/Notes";
import YourRfps from "pages/admin/my-edge/YourRfps";
import {
  isInternal,
  isRoleSuperAdminOrTrinityEditor,
  canEditVenue,
  canCreateVenue,
  hasVenues,
  canEditUsers,
  canCreateOwnChecksheet,
  isMarketingManager,
  canCreateAllChecksheets,
  isRoleTrinityUser,
  canSeeAnalytics,
  isRoleTrinityEdgeRFP,
  isRoleEdgeAdmin,
  isVenueRFPResponder,
  canAdministerCompany,
  isAgencyAdmin,
  isCompanyAdmin,
  isHeadOfProcurement,
  isHeadOfEvents,
  isEventSpecialist,
  canViewEvents,
  canManageEventCompletion,
  canManageEventPreData,
  isCompanyUser,
  isNonEventSpecialist,
  corporateAccountId,
  corporateAccountObject,
  hasActiveCorporateAccount,
  hasCorporateAccess,
  canAccessCorporateLocations,
  canAccessCorporateCalendar,
  canAccessCorporateDocuments,
  canAccessEdgeTeamUpdates,
} from "modules/permissions";
import  {LoadingHalfPage} from "Components/Loading";
import {canManageRfp, isRoleAccountManager} from '../modules/permissions';
import * as ROUTES from "./corporate_routes";
import CompanyContainer from "../pages/admin/corporate/CompanyContainer";
import ConfirmBooking from "../pages/admin/rfp/Confirmation/ConfirmBooking";
import CorporateAccountDocumentCategoryListContainer
    from "../pages/admin/corporate/documents/CorporateAccountDocumentCategoryListContainer";
import CorporateAccountDocumentByCategoryListContainer
    from "../pages/admin/corporate/documents/CorporateAccountDocumentByCategoryListContainer";
import EventSingleContainer from "../pages/admin/corporate/events/EventSingleContainer";
import EventsListContainer from "../pages/admin/corporate/events/EventsListContainer";
import CorporateAccountFavouriteLocationsContainer
    from "../pages/admin/corporate/locations/CorporateAccountFavouriteLocationsContainer";
import CalendarView from "../pages/admin/corporate/calendar-events/CalendarView";
import EventsContainer from "../pages/admin/corporate/calendar-events/EventsContainer";
import DraftRfps from "../pages/admin/my-edge/DraftRfps";
import Venue from "../pages/Venue";
import CorporateNews from "../pages/corporate/CorporateNews";
import SinglePost from "../pages/trinity-news/SinglePost";
import Article from "../pages/Article";
import Rfp from "../pages/Rfp";
import DraftRfp from "../pages/DraftRfp";
import CompareVenues from "../pages/CompareVenues";
import CompareVenuesFeedback from "../pages/CompareVenuesFeedback";
import Search from "../pages/Search";
import AdvancedSearch from "../pages/AdvancedSearch";
import EnquiryReportListContainer from "../pages/admin/corporate/reports/EnquiryReportListContainer";
import SnapshotReportContainer from "../pages/admin/corporate/reports/SnapshotReportContainer";
import SpendReportContainer from "../pages/admin/corporate/reports/SpendReportContainer";
import TemplatesListContainer from "../pages/admin/corporate/templates/TemplatesListContainer";
import Destination from "../pages/Destination";
import CorporateSpecialOffers from "../pages/corporate/CorporateSpecialOffers";
import {ViewOffer} from "../pages/offers";
import CorporateFooter from "../Components/corporate/CorporateFooter";
import ProposalShare from "../pages/Proposal";
import PrivateRoute from "./PrivateRoute";
import MonthlyEventsReportContainer from "../pages/admin/corporate/reports/MonthlyEventsReportContainer";
import EventOrganisersGuide from "../pages/EventOrganisersGuide";
import CorporateContactUs from "../pages/CorporateContactUs";
import CorporateInsights from "../pages/corporate/CorporateInsights";
import CorporateTeamUpdates from "../pages/corporate/CorporateTeamUpdates";
import ProposalAuthorise from "../pages/Proposal/ProposalAuthorise";

class Corporate extends React.Component {

    static propTypes = {
        isLoggedIn: bool.isRequired,
        history: object.isRequired,
        location: object.isRequired,
        isVenue: bool.isRequired,
        isAgency: bool.isRequired,
        isCorporate: bool.isRequired
    };

    constructor(props) {
        super(props);
        if (!props.isLoggedIn) {
            props.history.push(
                `/login?return=${encodeURIComponent(
                    props.location.pathname + props.location.search
                )}`
            );
            return;
        }
    }

    componentDidMount() {
        const {refreshUser, /*refreshUserVenues,*/ refreshUserCompany} = this.props;
        refreshUser();
        refreshUserCompany();
        // if (window.Tawk_API) {
        //     window.Tawk_API.showWidget();
        // }
    }

    render() {
        const {
            isInternal,
            hasVenues,
            isLoggedIn,
            isVenue,
            isAgency,
            isRoleEdgeAdmin,
            isHeadOfProcurement,
            canViewEvents,
            canManageEventCompletion,
            canManageEventPreData,
            isHeadOfEvents,
            isEventSpecialist,
            isNonEventSpecialist,
            isAgencyAdmin,
            isCompanyAdmin,
            canAdministerCompany,
            history,
            isCorporate,
            corporateAccountId,
            corporateAccount,
            isRefreshingUser = false,
            hasActiveCorporateAccount,
            isCompanyUser,
            hasCorporateAccess,
            canAccessCorporateLocations,
            canAccessCorporateCalendar,
            canAccessCorporateDocuments,
            canAccessEdgeTeamUpdates,
          generalInteraction
        } = this.props;
        const tawkToPropertyId = window.TAWK_TO_PROPERTY || '';
        const tawkWidgetId = window.TAWK_TO_KEY || '';
        const onChatMaximized = () => {
          generalInteraction({type: 'live_chat_started'});
        }

        if (!isLoggedIn) {
            history.push('/login');
        }

        if (isRefreshingUser) {
            return <LoadingHalfPage />
        }

        return (
            <div
                className={`md:flex md:flex-col md:min-h-screen admin-ui corporate-admin corporate-admin--var-to-do bg-off-white ${corporateAccount?.colour_scheme_classname ?? ''}`}>
              <TawkMessengerReact
                propertyId={tawkToPropertyId}
                widgetId={tawkWidgetId}
                onChatMaximized={onChatMaximized}
              />
                <AdminHeader corporateAccount={corporateAccount}/>
                <div className={`container mb-auto`}>
                    <Suspense fallback={<LoadingHalfPage/>}>
                        <Switch>

                            {(!isCorporate || !hasActiveCorporateAccount || !hasCorporateAccess) &&
                                <Route
                                    path="*"
                                    component={({ location }) => (
                                        <Redirect
                                            to="/"
                                        />
                                    )}
                                />
                                // replaced below with the above to keep querystring during redirect
                                // <Redirect from="*" to="/corporate*"/>
                            }

                            <Redirect exact from="/corporate" to="/corporate/dashboard"/>
                            <Redirect exact from="/corporate/sso" to="/corporate/dashboard"/>
                            <Redirect exact from="/corporate/token" to="/corporate/dashboard"/>
                            <Redirect exact from="/corporate/login" to="/corporate/dashboard"/>
                            <Redirect exact from="/corporate/my-edge/your-rfps" to="/corporate/events/your-rfps"/>
                            <Redirect exact from="/corporate/my-edge/draft-rfps" to="/corporate/events/draft-rfps"/>
                            <Redirect exact from="/corporate/corporate-accounts/undefined/view-documents"
                                      to={ROUTES.FRONTEND.CORPORATE_USER.DOCUMENTS.VIEW}/>

                            <Redirect exact
                                      from="/corporate/corporate-accounts/:acctId/view-documents/category/:category"
                                      to={ROUTES.FRONTEND.CORPORATE_USER.DOCUMENTS.SINGLE_CATEGORY}/>

                            <Route
                                path={ROUTES.FRONTEND.CORPORATE_USER.CMS.EVENT_ORGANISERS_GUIDE}
                                exact
                                component={EventOrganisersGuide}
                            />

                            <Route
                                path={ROUTES.FRONTEND.CORPORATE_USER.CMS.CORPORATE_CONTACT_US}
                                exact
                                component={CorporateContactUs}
                            />

                            {/* << RECREATED PUBLIC */}
                            <PrivateRoute
                                path={ROUTES.FRONTEND.CORPORATE_USER.PROPOSALS.SHARE}
                                exact
                                component={ProposalShare}
                            />
                            <PrivateRoute
                                path={ROUTES.FRONTEND.CORPORATE_USER.PROPOSALS.AUTHORISE}
                                exact
                                component={ProposalAuthorise}
                            />
                            <Route path="/corporate/search" component={Search}/>
                            <Route path="/corporate/advanced-search" component={AdvancedSearch}/>
                            <Route path="/corporate/request-for-proposal" component={Rfp}/>
                            <Route exact path="/corporate/venue/:venue" component={Venue}/>
                            <Route path="/corporate/article/:articleSlug" component={Article}/>
                            <Route path="/corporate/offer/:offerId" component={ViewOffer}/>
                            <Route path="/corporate/proposal/draft/:shareId" exact component={DraftRfp} />
                            <Route
                                path="/corporate/news/:section(inspiration|community|voice|the-word)/:slug"
                                component={SinglePost}
                            />
                            <Route
                                path="/corporate/:section(inspiration|community|voice|the-word)/:slug"
                                component={SinglePost}
                            />

                            <Route path="/corporate/compare-venues" component={CompareVenues}/>
                            <Route path="/corporate/compare-venues-feedback" component={CompareVenuesFeedback}/>
                            <Route path="/corporate/proposal/edit/:shareId" exact component={DraftRfp}/>
                            <Route path="/corporate/destination/:destination" component={Destination}/>
                            {/* RECREATED PUBLIC >> */}

                            {/* << RECREATED ADMIN */}
                            <Route path="/corporate/profile" component={Profile}/>
                            {/* RECREATED ADMIN >> */}

                            <Route path="/corporate/dashboard" component={CorpDashboard}/>
                            <Route path="/corporate/my-edge/shortlists" component={Shortlists}/>
                            <Route path="/corporate/my-edge/notes" component={Notes}/>
                            <Route
                                path="/corporate/my-edge/saved-searches"
                                component={SavedSearches}
                            />
                            {(canAdministerCompany || isRoleEdgeAdmin) && (
                                <Route
                                    path={ROUTES.FRONTEND.CORPORATE_USER.COMPANY.EDIT}
                                    exact
                                    component={CompanyContainer}
                                    id={corporateAccountId}
                                />
                            )}


                            {canAccessCorporateDocuments ? (
                              <Route
                                path={ROUTES.FRONTEND.CORPORATE_USER.DOCUMENTS.SINGLE_CATEGORY}
                                exact
                                component={CorporateAccountDocumentByCategoryListContainer}
                              />
                            ) : null}
                            {canAccessCorporateDocuments && (isHeadOfProcurement || isEventSpecialist || isHeadOfEvents || isNonEventSpecialist) ? (
                                <Route
                                    path={ROUTES.FRONTEND.CORPORATE_USER.DOCUMENTS.VIEW}
                                    component={CorporateAccountDocumentCategoryListContainer}
                                />
                            ) : null}

                            {canAccessCorporateLocations && (isHeadOfProcurement || isEventSpecialist || isHeadOfEvents || isNonEventSpecialist) ? (
                                <Route
                                    path={ROUTES.FRONTEND.CORPORATE_USER.LOCATIONS.VIEW}
                                    component={CorporateAccountFavouriteLocationsContainer}
                                />
                            ) : null}

                            {canAccessCorporateCalendar && (isHeadOfProcurement || isEventSpecialist || isHeadOfEvents || isNonEventSpecialist) ? (
                                <Route
                                    path={ROUTES.FRONTEND.CORPORATE_USER.CALENDAR.VIEW}
                                    component={CalendarView}
                                />
                            ) : null}

                            {(isHeadOfProcurement || isEventSpecialist || isHeadOfEvents) && isCompanyAdmin ? (
                                <Route
                                    path={ROUTES.FRONTEND.CORPORATE_USER.INDUSTRY_EVENTS.MANAGE}
                                    component={EventsContainer}
                                />
                            ) : null}

                            {/*{(isHeadOfProcurement || isEventSpecialist || isHeadOfEvents) && isCompanyAdmin ? (*/}
                            <Route
                                path={ROUTES.FRONTEND.CORPORATE_USER.NEWS.VENUE_NEWS}
                                component={CorporateNews}
                            />
                            <Route
                                path={ROUTES.FRONTEND.CORPORATE_USER.NEWS.INSIGHTS}
                                component={CorporateInsights}
                            />
                            {canAccessEdgeTeamUpdates ? (
                              <Route
                                path={ROUTES.FRONTEND.CORPORATE_USER.NEWS.CORPORATE_TEAM}
                                component={CorporateTeamUpdates}
                              />
                            ) : null}
                            {/*) : null}*/}
                            {/*{(isHeadOfProcurement || isEventSpecialist || isHeadOfEvents) && isCompanyAdmin ? (*/}
                            <Route
                                path={ROUTES.FRONTEND.CORPORATE_USER.NEWS.SPECIAL_OFFERS}
                                component={CorporateSpecialOffers}
                            />
                            {/*) : null}*/}


                            {/* RFP CORP/AGENT */}
                            {!isInternal && !hasVenues ? (
                                <Route
                                    path="/corporate/events/your-rfps/:id/confirm/:enquiryVenueDataId"
                                    component={ConfirmBooking}
                                />
                            ) : null}
                            {!isInternal && !hasVenues ? (
                                <Route
                                    path="/corporate/events/your-rfps/:id?"
                                    component={YourRfps}
                                />
                            ) : null}
                            {!isInternal && !hasVenues ? (
                                <Route
                                    path="/corporate/events/draft-rfps/:id?"
                                    component={DraftRfps}
                                />
                            ) : null}


                            {(isRoleEdgeAdmin || (canViewEvents && (isAgencyAdmin || (isCorporate && hasActiveCorporateAccount)))) && (
                                <Route
                                    path={ROUTES.FRONTEND.CORPORATE_USER.BOOKED_EVENTS.ALL}
                                    exact
                                    component={EventsListContainer}
                                />
                            )}

                            {(isRoleEdgeAdmin || ((canManageEventCompletion || canManageEventPreData) && (isAgencyAdmin || (isCorporate && hasActiveCorporateAccount)))) && (
                                <Route
                                    path={ROUTES.FRONTEND.CORPORATE_USER.BOOKED_EVENTS.SINGLE}
                                    exact
                                    component={EventSingleContainer}
                                />
                            )}

                            <Route path={ROUTES.FRONTEND.CORPORATE_USER.EVENTS.VIEW} component={Dashboard}/>
                          {(isCompanyUser || isCompanyAdmin) && (isHeadOfProcurement || isHeadOfEvents) && hasActiveCorporateAccount && (
                            <Route exact path={ROUTES.FRONTEND.CORPORATE_USER.REPORTS.VIEW} component={Dashboard}/>
                          )}
                          {(isCompanyUser || isCompanyAdmin) && (isHeadOfProcurement || isHeadOfEvents) && hasActiveCorporateAccount && (
                            <Route exact path={ROUTES.FRONTEND.CORPORATE_USER.REPORTS.ENQUIRY_LOG} component={EnquiryReportListContainer}/>
                          )}
                          {(isCompanyUser || isCompanyAdmin) && (isHeadOfProcurement || isHeadOfEvents) && hasActiveCorporateAccount && (
                            <Route exact path={ROUTES.FRONTEND.CORPORATE_USER.REPORTS.SNAPSHOT} component={SnapshotReportContainer}/>
                          )}
                          {(isCompanyUser || isCompanyAdmin) && (isHeadOfProcurement || isHeadOfEvents) && hasActiveCorporateAccount && (
                            <Route exact path={ROUTES.FRONTEND.CORPORATE_USER.REPORTS.SPEND_REPORT} component={SpendReportContainer}/>
                          )}
                            <Route path={ROUTES.FRONTEND.CORPORATE_USER.NEWS.VENUE_NEWS} component={Dashboard}/>
                            <Route path={ROUTES.FRONTEND.CORPORATE_USER.MY_EDGE.VIEW} component={Dashboard}/>
                            <Route path={ROUTES.FRONTEND.CORPORATE_USER.SETTINGS.VIEW} component={Dashboard}/>

                            <Route exact path={ROUTES.FRONTEND.CORPORATE_USER.TEMPLATES.VIEW} component={TemplatesListContainer}/>
                            {/* CORPORATE ROUTES >>>>>>> */}


                            <Route
                                render={() => (
                                    <NotFound>We're still working on this page</NotFound>
                                )}
                            />
                        </Switch>
                    </Suspense>
                </div>
                {!isCorporate ? <Footer isVenue={isVenue} isAgency={isAgency} loggedIn={isLoggedIn}/> :
                    <CorporateFooter/>}
            </div>
        );
    }
}

export default withRouter(
    connect(
        state => ({
            isRefreshingUser: state?.auth?.refreshingUser ?? false,
            hasActiveCorporateAccount: hasActiveCorporateAccount(state.auth),
            hasCorporateAccess: hasCorporateAccess(state.auth),
            corporateAccountId: corporateAccountId(state.auth),
            corporateAccount: corporateAccountObject(state.auth),
            isLoggedIn: isLoggedIn(state),
            isInternal: isInternal(state.auth),
            hasVenues: hasVenues(state.auth),
            canEditVenue: canEditVenue(state.auth),
            canEditUsers: canEditUsers(state.auth),
            canCreateOwnChecksheet: canCreateOwnChecksheet(state.auth),
            canCreateVenue: canCreateVenue(state.auth),
            canCreateAllChecksheets: canCreateAllChecksheets(state.auth),
            isRoleAccountManager: isRoleAccountManager(state.auth),
            isMarketingManager: isMarketingManager(state.auth),
            isRoleTrinityUser: isRoleTrinityUser(state.auth),
            canSeeAnalytics: canSeeAnalytics(state.auth),
            canManageRfp: canManageRfp(state.auth),
            isRoleTrinityEdgeRFP: isRoleTrinityEdgeRFP(state.auth),
            isRoleEdgeAdmin: isRoleEdgeAdmin(state.auth),
            isHeadOfProcurement: isHeadOfProcurement(state.auth),
            isHeadOfEvents: isHeadOfEvents(state.auth),
            isEventSpecialist: isEventSpecialist(state.auth),
            isNonEventSpecialist: isNonEventSpecialist(state.auth),
            canViewEvents: canViewEvents(state.auth),
            canManageEventCompletion: canManageEventCompletion(state.auth),
            canManageEventPreData: canManageEventPreData(state.auth),
            isVenueRFPResponder: isVenueRFPResponder(state.auth),
            isRoleSuperAdminOrTrinityEditor: isRoleSuperAdminOrTrinityEditor(
                state.auth
            ),
            canAdministerCompany: canAdministerCompany(state.auth),
            isVenue: isVenueAdmin(state),
            isAgency: isAgent(state),
            isAgencyAdmin: isAgencyAdmin(state.auth),
            isAgencyApproved: isAgencyApproved(state.auth),
            isCompanyAdmin: isCompanyAdmin(state.auth),
            isCompanyUser: isCompanyUser(state.auth),
            isCorporate: isCorporate(state),
            canAccessCorporateLocations: canAccessCorporateLocations(state.auth),
            canAccessCorporateCalendar: canAccessCorporateCalendar(state.auth),
            canAccessCorporateDocuments: canAccessCorporateDocuments(state.auth),
            canAccessEdgeTeamUpdates: canAccessEdgeTeamUpdates(state.auth),
        }),
        {refreshUser, refreshUserVenues, refreshUserCompany, generalInteraction}
    )(Corporate)
);
