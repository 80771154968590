import axios from 'axios';
import {
  SALES_SUMMARY_FIGURES_RECEIVED,
  SALES_SUMMARY_FIGURES_RECEIVED_ERROR,
  SALES_SUMMARY_FIGURES_REQUEST,
  SALES_SUMMARY_FIGURES_UNLOAD,
  SALES_SUMMARY_ITEM_RECEIVED,
  SALES_SUMMARY_ITEM_RECEIVED_ERROR,
  SALES_SUMMARY_ITEM_REQUEST,
  SALES_SUMMARY_ITEM_UNLOAD,
  SNAPSHOT_FIGURES_GRAPH_DATA_RECEIVED,
  SNAPSHOT_FIGURES_GRAPH_DATA_RECEIVED_ERROR, SNAPSHOT_FIGURES_GRAPH_DATA_REQUEST,
  SNAPSHOT_FIGURES_GRAPH_DATA_UNLOAD,
  SNAPSHOT_FIGURES_ITEMS_RECEIVED,
  SNAPSHOT_FIGURES_ITEMS_RECEIVED_ERROR,
  SNAPSHOT_FIGURES_ITEMS_REQUEST,
  SNAPSHOT_FIGURES_ITEMS_UNLOAD,
  SNAPSHOT_FIGURES_RECEIVED,
  SNAPSHOT_FIGURES_RECEIVED_ERROR,
  SNAPSHOT_FIGURES_REQUEST,
  SNAPSHOT_FIGURES_UNLOAD
} from '../reducers/types';
import { format } from "date-fns";

/** SnapShot */
export const snapshotFiguresRequest = () => ({
  type: SNAPSHOT_FIGURES_REQUEST
});

export const snapShotFiguresRequestReceived = (data) => ({
  type: SNAPSHOT_FIGURES_RECEIVED,
  data
})

export const snapShotFiguresError = (error) => ({
  type: SNAPSHOT_FIGURES_RECEIVED_ERROR,
  error
})

export const snapShotFiguresUnload = () => ({
  type: SNAPSHOT_FIGURES_UNLOAD,
})

export const snapshotFiguresItemsRequest = () => ({
  type: SNAPSHOT_FIGURES_ITEMS_REQUEST
});

export const snapShotFiguresItemsRequestReceived = (data) => ({
  type: SNAPSHOT_FIGURES_ITEMS_RECEIVED,
  data
})

export const snapShotFiguresItemsError = (error) => ({
  type: SNAPSHOT_FIGURES_ITEMS_RECEIVED_ERROR,
  error
})

export const snapShotFiguresItemsUnload = () => ({
  type: SNAPSHOT_FIGURES_ITEMS_UNLOAD,
})

export const snapshotFiguresGraphDataRequest = () => ({
  type: SNAPSHOT_FIGURES_GRAPH_DATA_REQUEST
});

export const snapShotFiguresGraphDataRequestReceived = (data) => ({
  type: SNAPSHOT_FIGURES_GRAPH_DATA_RECEIVED,
  data
})

export const snapShotFiguresGraphDataError = (error) => ({
  type: SNAPSHOT_FIGURES_GRAPH_DATA_RECEIVED_ERROR,
  error
})

export const snapShotFiguresGraphDataUnload = () => ({
  type: SNAPSHOT_FIGURES_GRAPH_DATA_UNLOAD,
})


/** Sales Summary */
export const salesSummaryFiguresRequest = () => ({
  type: SALES_SUMMARY_FIGURES_REQUEST
});

export const salesSummaryFiguresRequestReceived = (data) => ({
  type: SALES_SUMMARY_FIGURES_RECEIVED,
  data
})

export const salesSummaryFiguresError = (error) => ({
  type: SALES_SUMMARY_FIGURES_RECEIVED_ERROR,
  error
})

export const salesSummaryFiguresUnload = () => ({
  type: SALES_SUMMARY_FIGURES_UNLOAD,
})

/** Sales Summary Sum */
export const salesSummaryItemsRequest = () => ({
  type: SALES_SUMMARY_ITEM_REQUEST
});

export const salesSummaryItemsRequestReceived = (data) => {return{
  type: SALES_SUMMARY_ITEM_RECEIVED,
  data
}}

export const salesSummaryItemsError = (error) => ({
  type: SALES_SUMMARY_ITEM_RECEIVED_ERROR,
  error
})

export const salesSummaryItemsUnload = () => ({
  type: SALES_SUMMARY_ITEM_UNLOAD,
})




/** Snapshot Figures */
export const fetchSnapShotFigures = (venueIds, token) => {
  var queryString = Object.keys(venueIds).map(key => 'venue_ids[]' + '=' + venueIds[key]).join('&');
  return (dispatch) => {
    dispatch(snapshotFiguresRequest());
    return axios.get(window.API + '/reporting/snapshot-figures' + '?' +  queryString , {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => dispatch(snapShotFiguresRequestReceived(response.data)))
        .catch(error => dispatch(snapShotFiguresError(error)));
  };
};

export const fetchSnapShotFiguresItems = (venueIds, token) => {
  let queryString = Object.keys(venueIds).map(key => 'venue_ids[]' + '=' + venueIds[key]).join('&');

  return (dispatch) => {
    dispatch(snapshotFiguresItemsRequest());
    return axios.get(window.API + '/reporting/snapshot-figures-items' + '?' +  queryString , {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => dispatch(snapShotFiguresItemsRequestReceived(response.data)))
        .catch(error => dispatch(snapShotFiguresItemsError(error)));
  };
};
/** Snapshot Figures */
export const fetchSnapShotFiguresGraphData = (venueIds,token, startDate, endDate) => {
  let queryString = Object.keys(venueIds).map(key => 'venue_ids[]' + '=' + venueIds[key]).join('&');

  let start_date = format(startDate, "YYYY-MM-DD");
  let  end_date = format(endDate, "YYYY-MM-DDT23:59:59");
  return (dispatch) => {
    dispatch(snapshotFiguresGraphDataRequest());
    return axios.get(window.API + '/reporting/snapshot-figures-graph-data' + '?' +  queryString + '&start_date=' + start_date + '&end_date=' + end_date  , {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => dispatch(snapShotFiguresGraphDataRequestReceived(response.data)))
        .catch(error => dispatch(snapShotFiguresGraphDataError(error)));
  };
};


/** Snapshot Figures */
export const fetchSalesSummaryFigures = (venueIds, startDate, endDate,  token) => {
  let queryString = Object.keys(venueIds).map(key => 'venue_ids[]' + '=' + venueIds[key]).join('&');

  let start_date = format(startDate, "YYYY-MM-DD");
  let  end_date = format(endDate, "YYYY-MM-DDT23:59:59");
  return (dispatch) => {
    dispatch(salesSummaryFiguresRequest());
    return axios.get(window.API + '/reporting/sales-summary' + '?' +  queryString + '&start_date=' + start_date + '&end_date=' + end_date  , {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => dispatch(salesSummaryFiguresRequestReceived(response.data)))
        .catch(error => dispatch(salesSummaryFiguresError(error)));
  };
};


export const fetchSalesSummaryItems = (venueIds, startDate, endDate, type, token) => {
  let queryString = Object.keys(venueIds).map(key => 'venue_ids[]' + '=' + venueIds[key]).join('&');

  let start_date = format(startDate, "YYYY-MM-DD");
  let  end_date = format(endDate, "YYYY-MM-DDT23:59:59");
  return (dispatch) => {
    dispatch(salesSummaryItemsRequest());
    return axios.get(window.API + '/reporting/sales-summary-items' + '?' +  queryString + '&start_date=' + start_date + '&end_date=' + end_date + '&type=' + type  , {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => dispatch(salesSummaryItemsRequestReceived(response.data)))
        .catch(error => dispatch(salesSummaryItemsError(error)));
  };
};

