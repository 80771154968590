import React from 'react';
import ReportsDashboardContainer from "../reports/ReportsDashboardContainer";
import {ButtonBrand, ButtonPrimary} from "../../Button";
import {Link} from "react-router-dom";

export default function CorporateDashWelcome(props) {
    return <div className="w-full p-2 mb-3">
        {(props.user ?
            <div className={"border-b border-grey-md mb-2 text-white flex flex-row flex-wrap justify-between items-end pb-6 md:pb-8"}>
                <div className={'flex-no-shrink'}>
                {(props.firstName ?
                    <h2 className="font-body font-bold text-6xl">Hello {props.firstName1},</h2>
                : null)}
                <h2 className="mb-0 font-bold font-heading text-2rem md:text-4xl">Welcome to Corporate EDGE</h2>
                </div>
                <div className={'mt-4 md:mt-0 md:mb-1'}>
                    <Link to="/corporate/request-for-proposal">
                        <ButtonBrand
                            big
                            style={{'width': 'inherit'}}
                        >
                            Send a new enquiry
                        </ButtonBrand>
                    </Link>
                </div>


            </div>
            : null)}
        {/*<ReportsDashboardContainer ownReport={false}/>*/}
    </div>;
}