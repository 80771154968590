import React, {Suspense, useEffect} from "react";
import { connect } from "react-redux";
import { Redirect, Switch, Route, withRouter } from "react-router-dom";

import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

// auth
import {
  isInternal,
    isCorporate,
  hasVenues,
  token,
  user,
  isVenueAdmin,
  isAgent,
  isLoggedIn,
} from "modules/auth";

// layout
import Footer from "Components/Layout/Footer";

// routes
import NotFound from "pages/NotFound";
import Home from "pages/Home";
import Venue from "pages/Venue";
import Search from "pages/Search";
import AdvancedSearch from "pages/AdvancedSearch";
import Login from "pages/Login";
import Register from "pages/Register";
import Logout from "pages/Logout";

// Registration Routes
import Venues from "pages/Venues";
import CorporateEventBuyers from "pages/CorporateEventBuyers";
import Agents from "pages/Agents";
import Other from "pages/Other";

import RegistrationComplete from "pages/RegistrationComplete";
import CorporateRegistrationComplete from "pages/CorporateRegistrationComplete";
import AgencyRegistrationComplete from "pages/AgencyRegistrationComplete";
import VenueRegistrationComplete from "pages/VenueRegistrationComplete";

import Launch from "pages/Launch";
import Article from "pages/Article";
import ResetPassword from "pages/ResetPassword";
import UpdatePassword from "pages/UpdatePassword";
import { ViewOffer } from "../pages/offers";
import About from "pages/About";
import Help from "pages/Help";
import Contact from "pages/Contact";
import Disclaimer from "pages/Disclaimer";
import SuggestVenue from "pages/SuggestVenue";
import SiteVisitsHome from "pages/SiteVisits/SiteVisitsHome";
import SiteVisitFull from "pages/SiteVisits/SiteVisitFull";
import TermsAndConditions from "pages/TermsAndConditions";
import Privacy from "pages/Privacy";
import Cookies from "pages/Cookies";
import SearchForVenue from "pages/features/SearchForVenue";
import RefineVenueSearch from "pages/features/RefineVenueSearch";
import DefineVenue from "pages/features/DefineVenue";
import BuyItNow from "pages/features/BuyItNow";
import UkVenueLocations from "pages/UkVenueLocations";
import WorldwideVenueLocations from "pages/WorldwideVenueLocations";
import Location from "pages/Location";
import Rfp from "pages/Rfp";
import EdgeExpo2020 from "pages/EdgeExpo2020";
import UpgradeToEdgeVenuesPlus from "pages/Landing/UpgradeToEdgeVenuesPlus";

import SinglePost from "pages/trinity-news/SinglePost";
import Inspiration from "pages/trinity-news/Inspiration";
import CompareVenues from "pages/CompareVenues";
import Community from "pages/trinity-news/Community";
import TheWord from "pages/trinity-news/TheWord";
import Voice from "pages/trinity-news/Voice";
import Destination from "../pages/Destination";
import {LoadingHalfPage} from "../Components/Loading";
import EmailVerification from "../Components/Layout/EmailVerification";
import CmsContent from "Components/CmsContent";
import HeaderHome from "Components/Layout/HeaderHome";

import UpgradeVenue from "../pages/UpgradeVenue";
import PrivateRoute from "./PrivateRoute";
import {tawkTo} from "../modules/tawk-to";
import CompareVenuesFeedback from "../pages/CompareVenuesFeedback";
import * as ROUTES from "./corporate_routes";
import CompanyLandingPage from "../pages/Landing/CompanyLandingPage";
import AgencyLandingPage from "../pages/Landing/AgencyLandingPage";
import DraftRfp from "../pages/DraftRfp";
import ProposalShare from "../pages/Proposal";
import {fetchUserInvite} from "../redux/actions/corporateInvites";
import {hasActiveCorporateAccount, hasCorporateAccess} from "../modules/permissions";
import SSOLogin from "../pages/SSOLogin";
import TokenLogin from "../pages/TokenLogin";
import SSOError from "../pages/SSOError";
import ProposalAuthorise from "../pages/Proposal/ProposalAuthorise";
import ManualSSOLogin from "../pages/ManualSSOLogin";
import { generalInteraction } from "../modules/analytics";

// function Holding() {
//   return (
//     <div className="py-6">
//       <img src="/img/postcard.jpg" alt="postcard" />
//     </div>
//   );
// }
function Public({
  isInternal,
  isCorporate,
  hasVenues,
  location,
  user,
  token,
  isVenue,
  isAgency,
  loggedIn,
  hasActiveCorporateAccount,
  userInvite,
  history,
  hasCorporateAccess,
  generalInteraction
}) {
  const onChatMaximized = () => {
    generalInteraction({type: 'live_chat_started'});
  }
  const tawkToPropertyId = window.TAWK_TO_PROPERTY || '';
  const tawkWidgetId = window.TAWK_TO_KEY || '';
  return (
    <div className="md:flex md:flex-col md:min-h-screen">
      <EmailVerification user={user} token={token} />
      {location.pathname !== "/" && <HeaderHome relative />}
      {/* <div className="container py-8">
        <p>
          We are currently migrating data and therefore our site is unavailable.
        </p>
        <p>
          We apologise for any inconvenience this has caused and endeavour to
          keep this interruption to a minimum.
        </p>
        <p>Thank you for your patience.</p>
      </div> */}
      <div className={`mb-auto ${userInvite?.company?.corporate_account?.colour_scheme_classname ?? ''}`}>
        <TawkMessengerReact
          propertyId={tawkToPropertyId}
          widgetId={tawkWidgetId}
          onChatMaximized={onChatMaximized}
        />
        <Suspense fallback={<LoadingHalfPage />}>
          <Switch>
            <Route path="/logout" component={Logout} />
            {(isCorporate && hasActiveCorporateAccount) &&
                <Route
                    path="*"
                    component={({ location }) => (
                        <Redirect
                            to={{
                              ...location,
                              pathname: '/corporate'+location.pathname,
                            }}
                        />
                    )}
                />
                // replaced below with the above to keep querystring during redirect
                // <Redirect from="*" to="/corporate*"/>
            }
            <Route exact path="/" component={Home} />
            <Route path="/venues-in-:location" component={Location} />
            <Route path="/uk-venue-locations" component={UkVenueLocations} />
            <Route path={'/upgrade-venue'} component={UpgradeVenue} />
            <Route
              path="/worldwide-venue-locations"
              component={WorldwideVenueLocations}
            />
            <Route path="/search" component={Search} />
            <Route path="/advanced-search" component={AdvancedSearch} />
            <PrivateRoute path="/request-for-proposal" component={Rfp} />
            <PrivateRoute path="/proposal/draft/:shareId" exact component={DraftRfp} />
            <PrivateRoute path="/proposal/edit/:shareId" exact component={DraftRfp} />
            <PrivateRoute
              path={ROUTES.FRONTEND.PROPOSALS.SHARE}
              exact
              component={ProposalShare}
            />
            <PrivateRoute
                path={ROUTES.FRONTEND.PROPOSALS.AUTHORISE}
                exact
                component={ProposalAuthorise}
            />

            <Route path="/register" component={Register} />
            <Route path="/login" component={Login} />
            <Route path="/login-via-sso" component={ManualSSOLogin} />
            <Route path="/sso" component={SSOLogin} />
            <Route path="/sso-error" component={SSOError} />
            <Route path="/token" component={TokenLogin} />

            <Route
              path="/corporate-event-buyers"
              component={CorporateEventBuyers}
            />
            <Route path="/venues" component={Venues}/>
            <Route path="/agents" component={Agents}/>
            <Route path="/other" component={Other}/>
            <Route
              path={ROUTES.FRONTEND.USER_INVITES.COMPANY_LANDING_PAGE}
              component={CompanyLandingPage}
            />
            <Route
              path={ROUTES.FRONTEND.USER_INVITES.AGENCY_LANDING_PAGE}
              component={AgencyLandingPage}
            />

            <Route
              path="/registration-complete"
              component={RegistrationComplete}
            />
            <Route
              path="/corporate-registration-complete"
              component={CorporateRegistrationComplete}
            />
            <Route
              path="/agency-registration-complete"
              component={AgencyRegistrationComplete}
            />
            <Route
              path="/venue-registration-complete"
              component={VenueRegistrationComplete}
            />

            <Route path="/event" component={EdgeExpo2020} />
            <Route
              path="/upgrade-to-edge-venues-plus"
              component={UpgradeToEdgeVenuesPlus}
            />
            <Route path="/about" component={About} />
            <Route path="/help" component={Help} />
            <Route path="/disclaimer" component={Disclaimer} />
            <Route path="/contact" component={Contact} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/cookies" component={Cookies} />
            <Route path="/suggest-a-venue" component={SuggestVenue} />
            {/*#Hidden Site Visits*/}
            {isInternal ?
                <Route path="/site-visits/:siteVisitId" component={SiteVisitFull} />

            : null}
            {isInternal ?
                <Route path="/site-visits" component={SiteVisitsHome} />

                : null}

            <Route path="/reset-password" component={ResetPassword} />
            <Route
              path="/terms-and-conditions"
              component={TermsAndConditions}
            />
            <Route path="/search-for-venue" component={SearchForVenue}/>
            <Route path="/refine-venue-search" component={RefineVenueSearch}/>
            <Route path="/define-venue" component={DefineVenue}/>
            <Route path="/buy-it-now" component={BuyItNow}/>
            <Route path="/launch/:token" component={Launch}/>
            <Route path="/update-password/:token" component={UpdatePassword}/>
            <Route exact path="/venue/:venue" component={Venue}/>
            {/* <Route path="/compare-proposals" component={CompareProposals} /> */}
            <PrivateRoute path="/compare-venues" component={CompareVenues}/>
            <PrivateRoute path="/compare-venues-feedback" component={CompareVenuesFeedback}/>
            <Route path="/article/:articleSlug" component={Article}/>
            <Route path="/offer/:offerId" component={ViewOffer}/>
            <Route path="/inspiration" exact component={Inspiration}/>
            <Route path="/community" exact component={Community}/>
            <Route path="/voice" exact component={Voice}/>
            <Route path="/the-word" exact component={TheWord}/>
            <Route
              path="/:section(inspiration|community|voice|the-word)/:slug"
              component={SinglePost}
            />
            <Route path="/destination/:destination" component={Destination} />
            <Route
              path="/edge-news"
              render={() => <CmsContent title="EDGE News" dataKey="blog" />}
            />
            <Route
              path="/renovation-news"
              render={() => (
                <CmsContent title="Renovation News" dataKey="renovationNews" />
              )}
            />
            <Route
              path="/edge-venue-news"
              render={() => (
                <CmsContent title="EDGE Venue News" dataKey="news" />
              )}
            />
            <Route
              path="/special-offers"
              render={() => (
                <CmsContent title="Special Offers" dataKey="specialOffers" />
              )}
            />
            <Route
              path="/movers-and-shakers"
              render={() => (
                <CmsContent
                  title="Movers and Shakers"
                  dataKey="moversAndShakers"
                />
              )}
            />
            <Route
              path="/agency-rates"
              render={() => (
                <CmsContent title="Agency Rates" dataKey="agencyRates" />
              )}
            />
            <Route
              path="/fam-trips"
              render={() => <CmsContent title="Fam Trips" dataKey="famTrips" />}
            />
            <Route
              path="/commission-offers"
              render={() => (
                <CmsContent
                  title="Commission Offers"
                  dataKey="commissionOffers"
                />
              )}
            />
            <Route
              render={() => (
                <NotFound>
                  Sorry, the page your are looking for has not been found.
                  Please check the URL and try again.
                </NotFound>
              )}
            />
          </Switch>
        </Suspense>
      </div>
      <Footer isVenue={isVenue} isAgency={isAgency} loggedIn={loggedIn} />
      {/*<div className="hidden md:block">*/}
      {/*  <LiveChat />*/}
      {/*</div>*/}
    </div>
  );
}

export default withRouter(
  connect(state => ({
      isCorporate: isCorporate(state),
    isInternal: isInternal(state),
    hasVenues: hasVenues(state),
    token: token(state),
    user: user(state),
    isVenue: isVenueAdmin(state),
    isAgency: isAgent(state),
    loggedIn: isLoggedIn(state),
    hasActiveCorporateAccount: hasActiveCorporateAccount(state.auth),
    hasCorporateAccess: hasCorporateAccess(state.auth),
    ...state.userInvite,
  }), {fetchUserInvite, generalInteraction})(Public)
);
