import React from "react";
import classNames from "classnames";

class Table extends React.Component {
  static defaultProps = {
    showHeader: true,
  };

  render() {
    const {admin, showHeader = true, headers, data, maxTd, tableClass} = this.props;

    const renderHeader = header => {
      if (typeof header === "function") {
        return header();
      }
      if (typeof header === "string") {
        return header.startsWith(":") ? "" : header;
      }
      if (typeof header.display === "function") {
        return header.display();
      }
      return header.display;
    };

    return (
      <div
        className={classNames("w-full overflow-x-auto", {
          "bg-white p-3 mb-3": admin,
        }, tableClass)}
      >
        <table
          className={classNames({
            "border border-grey-md edge-table": !admin,
          })}
        >
          {showHeader ? (
            <thead>
            <tr>
              {headers.map((header, i) => {
                let headerClass = typeof header.class !== "undefined" ? header.class : "";
                return <th key={i} className={classNames({"p-3 text-left ": admin}) + headerClass}>
                  {renderHeader(header)}
                </th>
              })}
            </tr>
            </thead>
          ) : null}
          <tbody>
          {data.map((item, i) => (
            <React.Fragment key={i}>
              <tr
                key={i}
                className={classNames({
                  "bg-off-white": admin && i % 2 === 0,
                })}
              >
                {headers.map((header, i) => {
                  const dataProperty =
                    typeof header === "string" ? header : header.key;
                  return (
                    <td
                      className={classNames("font-normal", {
                        "border-l border-grey-md px-1 py-2": !admin,
                        "p-3 text-left": admin,
                      })}
                      style={maxTd ? {maxWidth: maxTd} : null}
                      key={i}
                    >
                      {typeof item[dataProperty] === "function"
                        ? item[dataProperty]()
                        : item[dataProperty]}
                    </td>
                  );
                })}
              </tr>
              {
                typeof item['extra_data'] === "function" ? item['extra_data']() : null
              }
            </React.Fragment>
          ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default Table;
