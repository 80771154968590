import axios from "axios";
import moment from "moment";
import pick from "lodash/pick";
import {getRoomStyles} from "../roomStyleService";
import {getStatusText} from '../../modules/rfp/enquiryVenueDataStatus';
import * as ROUTES from "../../Router/rfp_routes";
import {nameFormatter} from "../../utils";
import mapPackagesFromRfp from "../../pages/admin/rfp/Checksheet/utils/mapPackagesFromRfp";
import mapEquipmentFromRfp from "../../pages/admin/rfp/Checksheet/utils/mapEquipmentFromRfp";
import mapPackagesFromProposalData from "./utils/mapPackagesFromProposalData";
import mapCateringFromProposalData from "./utils/mapCateringFromProposalData";
import mapAccommodationFromRfp from "../../pages/admin/rfp/Checksheet/utils/mapAccommodationFromRfp";
import mapAccommodationFromProposalData from "./utils/mapAccommodationFromProposalData";
import mapRoomsFromRfp from "../../pages/admin/rfp/Checksheet/utils/mapRoomsFromRfp";
import mapEquipmentFromProposalData from "./utils/mapEquipmentFromProposalData";
import mapRoomsFromProposalData from "./utils/mapRoomsFromProposalData";
import mapSummaryItemsFromProposalData from "./utils/mapSummaryItemsFromProposalData";
import mapCommissionFromProposalData from "./utils/mapCommissionFromProposalData";
import mapAdditionalItemsFromProposalData from "./utils/mapAdditionalItemsFromProposalData";
import { formatDate } from "../../modules/dates";

const requestedBy = user => ({
    name: `${user.first_name} ${user.surname}`,
    company: user.job_title || "",
    email: user.email || "",
    isDeleted: user.isDeleted,
});

const getEnquiryTypeCount = async({term, status, dateFrom, dateTo, bookingDesk, venueFilter}, token) => {
    try {
        if (undefined === status) {
            status = ''
        }
        if (undefined === dateFrom) {
            dateFrom = ''
        }
        if (undefined === dateTo) {
            dateTo = ''
        }
        if (undefined === bookingDesk) {
            bookingDesk = ''
        }
        if (undefined === venueFilter) {
            venueFilter = ''
        }
        const response = await axios.get(`${window.API}/rfp/enquiry/rfps/count?term=${term}&status=${status}&dateFrom=${dateFrom}&dateTo=${dateTo}&booking_desk=${bookingDesk}&venue_id=${venueFilter}`,
            {
                headers: {Authorization: `Bearer ${token}`}
            });
        // couldn't see any reason to continue hardcoding this so just using the response.data which should be an array returned from be
        return {
            result: response.data
        };
        // return {
        //   result: {
        //     "agent": response.data.agent,
        //     "agentInputRequired": response.data.agentInputRequired,
        //     "direct": response.data.direct,
        //     "draft": response.data.draft,
        //     "my-managed": response.data["my-managed"],
        //     "managed": response.data.managed,
        //     "new-edge": response.data["new-edge"],
        //     "new-trinity": response.data["new-trinity"],
        //   },
        // }
    } catch (error) {
        console.error(error);
        return { error };
    }
}
const getClientName = client => {
    return nameFormatter(client, false);
}

const formatLinkedEnquiryData = (enquiries) => {
    return enquiries.map(enquiry => {
        let department = '';
        if (enquiry.department && enquiry.department.parent) {
            department = enquiry.department.parent.name + ' > ' + enquiry.department.name
        } else if (enquiry.department) {
            department = enquiry.department.name
        }
        let bookingDesk = ''
        if (enquiry.corporate_specific_data && enquiry.corporate_specific_data.booking_desk) {
            bookingDesk = enquiry.corporate_specific_data.booking_desk.name;
        } else if (enquiry.corporate_specific_data && enquiry.corporate_specific_data.agency_booking_desk) {
            bookingDesk = enquiry.corporate_specific_data.agency_booking_desk.name;
        }
        return {
            date_from: formatDate(enquiry.date_from, "ddd Do MMM YYYY"),
            type: enquiry.type || "new",
            is_highly_confidential: enquiry.is_highly_confidential,
            rfp_xpress_service: enquiry.rfp_xpress_service,
            agent_input_required: enquiry.agent_input_required,
            ref_id: enquiry.ref_id ? enquiry.ref_id : enquiry.client_company ? enquiry.client_company.code + enquiry.id : enquiry.minimal_user.company ? enquiry.minimal_user.company.code + enquiry.id : enquiry.id,
            company_code: enquiry.client_company ? enquiry.client_company.code + enquiry.id : enquiry.id,
            company_branch: enquiry.company_branch ? enquiry.company_branch.name : null,
            company_name: enquiry.client_company ? enquiry.client_company.name : enquiry.minimal_user.email,
            booking_desk: bookingDesk,
            department: department,
            id: enquiry.id,
            event_name: enquiry.event_name,
            requested_by: requestedBy(enquiry.minimal_user),
            is_managed: (!!enquiry.managing_trinity_user),
            managed_by: enquiry.managing_trinity_user,
            first_assigned_at: enquiry.first_assigned_at,
            user_type: enquiry.minimal_user.user_type.name,
            venues_contacted: enquiry.enquiry_venue_datas.length,
            date_submitted: moment(enquiry.created_at).format("DD/MM/YYYY HH:mm"),
            event_start_date: moment(enquiry.date_from).format("DD/MM/YYYY"),
            status: getStatusText(enquiry.enquiry_venue_datas, enquiry),
            enquiry_status: enquiry.enquiry_status,
            assigned_to: enquiry.managing_trinity_user ? enquiry.managing_trinity_user.first_name + ' ' + enquiry.managing_trinity_user.surname : null,
            enquirer_unread_message_count: enquiry.enquirer_unread_message_count,
            venue_unread_message_count: enquiry.venue_unread_message_count,
            all_venues_have_contact_details: !!enquiry.all_enquiry_venues_have_emails,
            venues: enquiry.enquiry_venue_datas.map(evd => ({
                name: evd.minimal_venue.name,
                email: evd.minimal_venue.email
            })),
            commission_collected: enquiry.commission_collected,
            estimated_value: enquiry.estimated_value,
            estimated_commission: enquiry.estimated_commission,
            collect_commission: enquiry.collect_commission,
            super_agency_code: enquiry.super_agency_code,
            client_name: getClientName(enquiry.client)
        }
    });
}

const getEnquiries = async ({page, term, type, sortBy, status, dateFrom, dateTo, bookingDesk, venueFilter}, token) => {
    try {
        if (undefined === status) {
            status = ''
        }
        if (undefined === dateFrom) {
            dateFrom = ''
        }
        if (undefined === dateTo) {
            dateTo = ''
        }
        if (undefined === bookingDesk) {
            bookingDesk = ''
        }
        if (undefined === venueFilter) {
            venueFilter = ''
        }
        const response = await axios.get(
            `${window.API}/rfp/enquiry?page=${page}${
                type ? "&type=" + type : ""
            }&term=${term}&status=${status}&dateFrom=${dateFrom}&dateTo=${dateTo}&booking_desk=${bookingDesk}&venue_id=${venueFilter}&sort_by=${sortBy.field}:${sortBy.order}`,
            {
                headers: {Authorization: `Bearer ${token}`},
            }
        );

        if (!response.data.data) {
            return {
                result: {
                    enquiries: [],
                    current_page: 1,
                    last_page: 1,
                },
            };
        }
        const enquiries = response.data.data.map(({data: enquiry}) => {
            let department = '';
            if (enquiry.department && enquiry.department.parent) {
                department = enquiry.department.parent.name + ' > ' + enquiry.department.name
            } else if (enquiry.department) {
                department = enquiry.department.name
            }
            let bookingDesk = ''
            if (enquiry.corporate_specific_data && enquiry.corporate_specific_data.booking_desk) {
                bookingDesk = enquiry.corporate_specific_data.booking_desk.name;
            } else if (enquiry.corporate_specific_data && enquiry.corporate_specific_data.agency_booking_desk) {
                bookingDesk = enquiry.corporate_specific_data.agency_booking_desk.name;
            }
            return {
                type: enquiry.type || "new",
                is_highly_confidential: enquiry.is_highly_confidential,
                rfp_xpress_service: enquiry.rfp_xpress_service,
                agent_input_required: enquiry.agent_input_required,
                ref_id: enquiry.ref_id ? enquiry.ref_id : enquiry.client_company ? enquiry.client_company.code + enquiry.id : enquiry.minimal_user.company ? enquiry.minimal_user.company.code + enquiry.id : enquiry.id,
                company_code: enquiry.client_company ? enquiry.client_company.code + enquiry.id : enquiry.id,
                company_branch: enquiry.company_branch ? enquiry.company_branch.name : null,
                company_name: enquiry.client_company ? enquiry.client_company.name : enquiry.minimal_user.email,
                booking_desk: bookingDesk,
                department: department,
                id: enquiry.id,
                event_name: enquiry.event_name,
                requested_by: requestedBy(enquiry.minimal_user),
                is_managed: (!!enquiry.managing_trinity_user),
                managed_by: enquiry.managing_trinity_user,
                first_assigned_at: enquiry.first_assigned_at,
                user_type: enquiry.minimal_user.user_type.name,
                venues_contacted: enquiry.enquiry_venue_datas.length,
                date_submitted: moment(enquiry.created_at).format("DD/MM/YYYY HH:mm"),
                event_start_date: moment(enquiry.date_from).format("DD/MM/YYYY"),
                status: getStatusText(enquiry.enquiry_venue_datas, enquiry),
                enquiry_status: enquiry.enquiry_status,
                assigned_to: enquiry.managing_trinity_user ? enquiry.managing_trinity_user.first_name + ' ' + enquiry.managing_trinity_user.surname : null,
                enquirer_unread_message_count: enquiry.enquirer_unread_message_count,
                venue_unread_message_count: enquiry.venue_unread_message_count,
                all_venues_have_contact_details: !!enquiry.all_enquiry_venues_have_emails,
                venues: enquiry.enquiry_venue_datas.filter(evd => {
                    return typeof evd.minimal_venue !== 'undefined' && evd.minimal_venue
                }).map(evd => ({
                    name: evd.minimal_venue.name,
                    email: evd.minimal_venue.email
                })),
                has_linked_enquiries: enquiry.linked_enquiries_count > 0 || enquiry.linked_enquiry_id !== null,
                commission_collected: enquiry.commission_collected,
                estimated_value: enquiry.estimated_value,
                estimated_commission: enquiry.estimated_commission,
                collect_commission: enquiry.collect_commission,
                super_agency_code: enquiry.super_agency_code,
                client_name: getClientName(enquiry.client)
            }
        });

        return {
            result: {
                enquiries,
                current_page: response.data.meta ? response.data.meta.current_page : 1,
                last_page: response.data.meta ? response.data.meta.last_page : 1,
            },
        };
    } catch (error) {
        return {error};
    }
};

const getEnquiryByShareId = async (shareId, token) => {
    try {
        const response = await axios.get(`${window.API}/rfp/enquiry/share/${shareId}`, {
            headers: {Authorization: `Bearer ${token}`}
        });
        return singleEnquiryData(response, token);
    } catch (error) {
        return {error};
    }
}

const getEnquiryByAuthoriseId = async (authoriseId, token) => {
    try {
        const response = await axios.get(`${window.API}/rfp/enquiry/authorise/${authoriseId}`, {
            headers: {Authorization: `Bearer ${token}`}
        });
        return singleEnquiryData(response, token);
    } catch (error) {
        return {error};
    }
}

const deDupeGetEnquiryByIdRequests = {};
const getEnquiryById = async (id, token) => {
    if (Object(deDupeGetEnquiryByIdRequests).hasOwnProperty(id)) {
        const response =  await deDupeGetEnquiryByIdRequests[id];
        return singleEnquiryData(response, token);
    }
    try {
        deDupeGetEnquiryByIdRequests[id] = axios.get(`${window.API}/rfp/enquiry/${id}`, {
            headers: {Authorization: `Bearer ${token}`},
        });

        const response = await deDupeGetEnquiryByIdRequests[id];

        // dedupe this request for 2 seconds
        setTimeout(()=>{
            delete deDupeGetEnquiryByIdRequests[id];
        },2000);
        return singleEnquiryData(response, token);
    } catch (error) {
        return {error};
    }
};

const mapEnquiryVenueData = (evd) => {
    let currency = (evd && evd.currency) ? evd.currency : {
        name: "British Pound",
        currency_code: "GBP",
        html_code: "&pound;"
    };
    if (evd.currency === null) {
        evd.currency = currency;
    }

    let packages = mapPackagesFromProposalData(evd.proposal_data.conference_packages, currency.currency_code !== 'GBP');
    let supplementary_items = mapCateringFromProposalData(evd.proposal_data.supplementary_items, currency.currency_code !== 'GBP');
    let additional_items = mapAdditionalItemsFromProposalData(evd.proposal_data.additional_items, currency.currency_code !== 'GBP');
    let accommodations = mapAccommodationFromProposalData(evd.proposal_data.accommodations, evd.date_from_override, currency.currency_code !== 'GBP');
    let meeting_rooms = mapRoomsFromProposalData(evd.proposal_data.meeting_rooms, currency.currency_code !== 'GBP');
    let equipment = mapEquipmentFromProposalData(evd.proposal_data.equipment, currency.currency_code !== 'GBP');
    let summary = mapSummaryItemsFromProposalData(evd.proposal_data.summary, currency.currency_code !== 'GBP');
    let commission_items = mapCommissionFromProposalData(evd.proposal_data.commission_items, currency.currency_code !== 'GBP');
    return {
        ...evd,
        proposal_data: {
            ...evd.proposal_data,
            conference_packages: packages,
            supplementary_items,
            additional_items,
            accommodations,
            meeting_rooms,
            equipment,
            summary,
            commission_items
        },
    };

}
const singleEnquiryData = async (response, token) => {
    try {
        const {result: rooms} = await getRoomStyles(null, token);
        const brief = {
            ...pick(response.data.data, [
                "agent_input_required",
                "additional_notes",
                "event_name",
                "enquiry_status",
                "cancellation_date",
                "cancellation_reason",
                "cancellation_charges_apply",
                "cancellation_agency_note",
                "cancelled_by",
                "decline_reason_id",
                "decline_reason_text",
                "decline_reason",
                "decline_date",
                "declined_by",
                "number_of_delegates",
                "maximum_number_of_delegates",
                "bedroom_dates",
                "date_from",
                "date_to",
                "date_flexible",
                "date_flexible_notes",
                "bedrooms_required",
                "bedroom_additional_notes",
                "back_projection",
                "syndicate_room",
                "syndicate_room_notes",
                "exhibition_space",
                "exhibition_space_notes",
                "private_dining",
                "private_dining_notes",
                "any_equipment",
                "any_equipment_notes",
                "attachments",
                "share_id",
                "authorise_id",
                "authorised_state",
                "authorising_user",
                "authorised_at",
                "event_timings",
                "date_decision_estimate",
                "rfp_xpress_service",
                "custom_field_values",
                "custom_field_questions",
                "created_at",
                "is_highly_confidential",
                "decision_making_process",
                "probing_questions"
            ]),
            date_submitted: moment(response.data.data.created_at).format("DD/MM/YYYY HH:mm"),
            room_style: (
                rooms.find(room => room.value === response.data.data.room_style_id) ||
                {}
            ).text,
        };
        let managing_trinity_user = null;
        if (response.data.data.managing_trinity_user) {
            managing_trinity_user = {
                id: response.data.data.managing_trinity_user.id,
                email: response.data.data.managing_trinity_user.email,
                phone: response.data.data.managing_trinity_user.phone,
                user_type: response.data.data.managing_trinity_user.user_type.name,
                name: `${response.data.data.managing_trinity_user.first_name} ${response.data.data.managing_trinity_user.surname}`,
            }
        }
        let syndicateRooms = [];
        if (response.data.data.syndicate_rooms) {
            response.data.data.syndicate_rooms.map(syndicateRoom => {
                syndicateRooms.push(syndicateRoom)
            })
        }
        brief.syndicateRooms = syndicateRooms;

        let event = null;
        if (response.data.data.event) {
            event = {
                id: response.data.data.event.id,
                cancellation_charges_apply: response.data.data.event.cancellation_charges_apply,
                contract_file: response.data.data.event.contract_file,
                contract_uploader: response.data.data.event.contract_uploader,
                contract_file_url: response.data.data.event.contract_file_url,
                contract_upload_date: response.data.data.event.contract_upload_date,
                event_status: response.data.data.event.event_status,
                requires_invoicing: response.data.data.event.requires_invoicing,
            }
        }

        const brief_preferences = response.data.data.brief_preferences;

        const client_details = {
            ref_id: response.data.data.ref_id ? response.data.data.ref_id : response.data.data.client_company ? response.data.data.client_company.code + response.data.data.id : response.data.data.id,
            email: response.data.data.client ? response.data.data.client.email : response.data.data.minimal_user.email,
            phone: response.data.data.client ? (response.data.data.client.phone ? response.data.data.client.phone : response.data.data.client.mobile) : response.data.data.minimal_user.phone,
            user_type: response.data.data.client ? 'N/A' : response.data.data.minimal_user.user_type.name,
            name: response.data.data.client ? `${response.data.data.client.first_name} ${response.data.data.client.surname}` : `${response.data.data.minimal_user.first_name} ${response.data.data.minimal_user.surname}`,
            original_client: response.data.data.original_client ? nameFormatter(response.data.data.original_client) : null,
        };

        const agency_details = {
            name: response.data.data.minimal_user.agency ? response.data.data.minimal_user.agency.name : null
        };

        const company_details = {
            // name: response.data.data.minimal_user.company ? response.data.data.minimal_user.company.name : null
            name: response.data.data.client_company ? response.data.data.client_company : null,
            branch: response.data.data.company_branch ? response.data.data.company_branch.name : null
        };

        const agency_specific_data = {
            client_name: response.data.data.client ? `${response.data.data.client.first_name} ${response.data.data.client.surname}` : '',
            original_client: response.data.data.original_client ? nameFormatter(response.data.data.original_client) : null,
            company_name: response.data.data.client_company ? `${response.data.data.client_company.name}` : '',
            company_branch: response.data.data.company_branch ? response.data.data.company_branch.name : '',
            industry_sector: response.data.data.industry,
            share_with_venues: response.data.data.share_with_venues,
            estimated_value: response.data.data.estimated_value,
            estimated_commission: response.data.data.estimated_commission,
            client_email: response.data.data.client ? response.data.data.client.email : '',
            client_phone: response.data.data.client ? response.data.data.client.phone : ''
        };

        let enquiry_venue_datas = [];
        if (response.data.data.enquiry_venue_datas.length > 0) {
            let {data: venues} = await axios.get(`${window.NODE_AS}/compare`, {
                params: {
                    venues: response.data.data.enquiry_venue_datas.map(
                        venue => venue.venue_id
                    ),
                },
            });
            enquiry_venue_datas = response.data.data.enquiry_venue_datas.map(enquiry_venue_data => {
                let currency = (enquiry_venue_data && enquiry_venue_data.currency) ? enquiry_venue_data.currency : {
                    name: "British Pound",
                    currency_code: "GBP",
                    html_code: "&pound;"
                };
                if (enquiry_venue_data.currency === null) {
                    enquiry_venue_data.currency = currency;
                }

                let packages = mapPackagesFromProposalData(enquiry_venue_data.proposal_data.conference_packages, currency.currency_code !== 'GBP');
                let supplementary_items = mapCateringFromProposalData(enquiry_venue_data.proposal_data.supplementary_items, currency.currency_code !== 'GBP');
                let additional_items = mapAdditionalItemsFromProposalData(enquiry_venue_data.proposal_data.additional_items, currency.currency_code !== 'GBP');
                let accommodations = mapAccommodationFromProposalData(enquiry_venue_data.proposal_data.accommodations, enquiry_venue_data.date_from_override, currency.currency_code !== 'GBP');
                let meeting_rooms = mapRoomsFromProposalData(enquiry_venue_data.proposal_data.meeting_rooms, currency.currency_code !== 'GBP');
                let equipment = mapEquipmentFromProposalData(enquiry_venue_data.proposal_data.equipment, currency.currency_code !== 'GBP');
                let summary = mapSummaryItemsFromProposalData(enquiry_venue_data.proposal_data.summary, currency.currency_code !== 'GBP');
                let commission_items = mapCommissionFromProposalData(enquiry_venue_data.proposal_data.commission_items, currency.currency_code !== 'GBP');
                return {
                    ...enquiry_venue_data,
                    proposal_data: {
                        ...enquiry_venue_data.proposal_data,
                        conference_packages: packages,
                        supplementary_items,
                        additional_items,
                        accommodations,
                        meeting_rooms,
                        equipment,
                        summary,
                        commission_items
                    },
                    venue: venues.find(
                        venue => venue.id === enquiry_venue_data.venue_id
                    ),
                }
            });
        }

        return {
            result: {
                id: response.data.data.id,
                ref_id: response.data.data.ref_id,
                collect_commission: response.data.data.collect_commission,
                is_revised: response.data.data.is_revised,
                enquiry_status: response?.data?.data?.enquiry_status?.code,
                brief,
                brief_preferences,
                event,
                client_details,
                agency_specific_data,
                managing_trinity_user,
                agency_details,
                company_details,
                enquiry_venue_datas,
                requires_authorisation: response.data.data.requires_authorisation
            },
        };
    } catch (error) {
        return {error};
    }
}

const submitEnquiry = async (data, token, editEnquiry = false, submitToAgent = false, enquiryToSubmitted = false, submitToDraft = false, requiresAuthorising = false) => {
    let url = `${window.API}/rfp/enquiry`;
    if (data.bedrooms_required) {
        for (let i = 0; i < data.bedroom_dates.length; i++) {
            data.bedroom_dates[i].date = moment(data.bedroom_dates[i].date).format('YYYY-MM-DD HH:mm:ss');
        }
    }
    let formData = new FormData();
    data.files.forEach(file => {
        formData.append('files[]', file, file.name);
    });
    if (data.syndicate_rooms) {
        data.syndicate_rooms.forEach(syndicateRoom => {
            formData.append('syndicate_rooms[]', JSON.stringify(syndicateRoom));
        })
    }
    if (data.linked_enquiries) {
        data.linked_enquiries.forEach(linkedEnquiry => {
            formData.append('linked_enquiries[]', JSON.stringify(linkedEnquiry));
        })
    }
    if (data.venues) {
        const uniqueVenues = [...new Set(data.venues)];
        uniqueVenues.forEach(venue => {
            if (venue) {
                formData.append('venues[]', venue);
            }
        });
    }
    if (!data.rfp_xpress_service) {
        formData.append('rfp_xpress_service', 0)
    }
    // else {
    //   formData.append('draft_enquiry', 1);
    // }
    if (submitToDraft) {
        formData.append('draft_enquiry', 1);
    } else {
        formData.append('draft_enquiry', 0);
    }

    if (requiresAuthorising) {
        formData.append('requires_authorisation', 1);
    } else {
        formData.append('requires_authorisation', 0);
    }

    data.bedrooms_required ? data.bedroom_dates.forEach(date => {
        formData.append('bedroom_dates[]', JSON.stringify(date));
    }) : formData.append('bedroom_dates[]', null);
    if (data.custom_fields) {
        // Loop through the object
        for (let [key, val] of Object.entries((data.custom_fields))) {
            // append each item to the formData (converted to JSON strings)
            if (key.includes('date')) {
                val = moment(val).add(12, 'hours');
            }
            formData.append(`custom_fields[${key}]`, JSON.stringify(val));
        }
    }
    formData.append('date_from', moment(data.date_from).add(12, "hours"))
    formData.append('date_to', moment(data.date_to).add(12, "hours"))
    Object.keys(data).forEach((key) => {
        if (key !== 'files' && key !== 'venues' && key !== 'date_from' && key !== 'date_to' && key !== 'bedroom_dates' && key !== 'custom_fields' && key !== 'syndicate_rooms' && key !== 'linked_enquiries') {
            if (data[key] !== null || data[key] !== "null") {
                formData.append(key, data[key])
            }
        }
    })
    if (submitToAgent) {
        formData.append('submit_to_agent', 1)
        formData.append('agent_input_required', 1)
    }

    if (editEnquiry) {
        formData.append('_method', 'PATCH');
        if (!data.company_id) {
            formData.delete('company_id');
        }
        if (data.date_decision_estimate === null) {
            formData.delete('date_decision_estimate');
        }
        if (data.department_id === null) {
            formData.delete('department_id');
        }
        if (data.estimated_commission === null) {
            formData.delete('estimated_commission');
        }
        if (data.estimated_value === null) {
            formData.delete('estimated_value');
        }
        if (!data.is_trinity_enquiry) {
            formData.delete('is_trinity_enquiry');
        }
        if (data.meeting_host_id === null) {
            formData.delete('meeting_host_id');
        }
        if (data.booking_desk_id === null) {
            formData.delete('booking_desk_id');
        }
        if (enquiryToSubmitted) {
            formData.append('submit_enquiry', 1);
            formData.delete('agent_input_required', 1);
            formData.append('agent_input_required', 0);
        }
        formData.delete('authorised_state');
        url = window.API + ROUTES.API.BRIEF.EDIT.replace(':shareId', data.id);
    }
    Object.keys(data).forEach((key) => {
        if (key !== 'files' && key !== 'venues' && key !== 'date_from' && key !== 'date_to' && key !== 'bedroom_dates') {
            if (data[key] === null || data[key] === "null") {
                formData.delete(key)
            }
        }
    })

    try {
        const response = await axios.post(
            url,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return {result: response.data};
    } catch (error) {
        return {error};
    }
};

const toggleCommissionCollected = async (rfpId, token) => {
    try {
        const response = await axios.post(
            `${window.API}/rfp/enquiry/${rfpId}/commission-collected`,
            null,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return {result: response.data};
    } catch (error) {
        return {error};
    }
}

export {getEnquiries, getEnquiryById, submitEnquiry, toggleCommissionCollected, getEnquiryByShareId, getEnquiryTypeCount, getEnquiryByAuthoriseId, formatLinkedEnquiryData, mapEnquiryVenueData};
