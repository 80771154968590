export const ENQUIRY_STATUS = {
  DRAFT: 'DRAFT',
  CANCELLED: 'CANCELLED',
  CONFIRMED: 'CONFIRMED',
  DENIED: 'DENIED'
}

export const GOOGLE_MAPS_API_KEY = `AIzaSyAaTrHlUOn97GNkea1Aq3gaes2eetTcBV4`

export const BOOKED_EVENT_STATUS = {
    PROVISIONAL: 0,
    DENIED: 10,
    REVISING_CONFIRMATION: 11,
    CONFIRMED: 20,
    STATUS_CANCELLED_PENDING_INVOICE: 25,
    PENDING_INVOICE: 30,
    COMPLETED: 40,
    CANCELLED: 50
}

export const COUNTRIES = {
  UNITED_KINGDOM: 77
}

// Taken from user_types.id in the backend
export const USER_TYPES = {
  CORPORATE: 1,
  AGENCY: 2,
  INDIVIDUAL: 3,
  CHARITY: 4,
  OTHER: 5,
  VENUE: 6
}

export const EDGE_ENQUIRIES_EMAIL = 'enquiries@edgevenues.com';
export const EDGE_PHONE_NUMBER = '01780 484 051';

// temporary solution to hide auto and reassign for roche
export const ROCHE_COMPANY_CODE = 'RPL';

export const ROCHE_THIRD_PARTY_TEXT = "Roche are not responsible for booking venues for Third Party Meetings where Roche have a presence. The Third Party is responsible for, and must control the selection and booking of the venue. We therefore refer you to SOP-0112349 (Third Party Organised Meetings with Roche Presence) and will not progress this enquiry further. Please contact Affiliate Enablement directly should you have any queries."
export const DEFAULT_AGENCY_CONTACT = {
  name: 'EDGE Venues',
  email_address: EDGE_ENQUIRIES_EMAIL,
  phone_number: EDGE_PHONE_NUMBER
}

export const USER_TOKEN_DISABLED = 'TOKEN_DISABLED';