
import React from "react";
import {mapEnquiryVenueData} from "../../../../../services/rfpService/enquiries";

let buildCurrencyFormatter = (enquiryVenueData) => {
    return typeof enquiryVenueData.currency?.currency_code !== 'undefined' ? new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: enquiryVenueData.currency.currency_code,
        // minimumFractionDigits: 0,
        // maximumFractionDigits: 0,
    }) : new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "GBP",
        // minimumFractionDigits: 0,
        // maximumFractionDigits: 0,
    });
}

let setInitialDelegates = (enquiryVenueData) => {
    let delegates = {};

    enquiryVenueData.proposal_data.conference_packages.forEach((cp) => {
        delegates[`conference_${cp.id}`] = cp.no_of_delegates;
    });
    enquiryVenueData.proposal_data.supplementary_items.forEach((si) => {
        delegates[`catering_${si.id}`] = si.quantity_required;
    });
    enquiryVenueData.proposal_data.accommodations.forEach((ac) => {
        delegates[`accommodation_${ac.id}`] = ac.no_of_rooms;
    });
    return delegates;
}

let setInitialMinDelegates = (enquiryVenueData) => {
    let delegates = {};

    enquiryVenueData.proposal_data.conference_packages.forEach((cp) => {
        delegates[`conference_${cp.id}`] = cp.min_number_of_delegates_for_qualify;
    });
    enquiryVenueData.proposal_data.supplementary_items.forEach((si) => {
        delegates[`catering_${si.id}`] = si.meeting_room ? si.meeting_room.min_number_of_delegates_for_qualify : null;
    });
    enquiryVenueData.proposal_data.accommodations.forEach((ac) => {
        delegates[`accommodation_${ac.id}`] = ac.min_number_of_delegates_for_qualify;
    });
    return delegates;
}


let setInitialRemove = (enquiryVenueData) => {
    let remove = {};
    enquiryVenueData.proposal_data.conference_packages.forEach((cp) => {
        remove[`conference_${cp.id}`] = false;
    });
    enquiryVenueData.proposal_data.supplementary_items.forEach((si) => {
        remove[`catering_${si.id}`] = false;
    });
    enquiryVenueData.proposal_data.accommodations.forEach((ac) => {
        remove[`accommodation_${ac.id}`] = false;
    });
    enquiryVenueData.proposal_data.meeting_rooms.forEach((mr) => {
        remove[`meeting_${mr.id}`] = false;

    });
    enquiryVenueData.proposal_data.equipment.forEach((eq) => {
            remove[`equip_${eq.id}`] = false
    });
    return remove;
}

let flagChanges = (initialValues, values, removedRows, newValue, name, setFlag) => {
    let type = name.slice(0, name.indexOf("["));
    let value = name.slice(name.indexOf("[") + 1, name.indexOf("]"));
    let checkPristine = true;
    if(type === 'delegates') {
        if (parseInt(newValue) !== parseInt(initialValues[type][value])) {
            setFlag(true);
            checkPristine = false;
        }
    }else{
        if (newValue !== initialValues[type][value]) {
            setFlag(true);
            checkPristine = false;
        }
    }
    if(checkPristine) {
        let initial = true;
        if(values) {
            for (const [key, value] of Object.entries(values)) {

                let checkValue = value;
                if(key === name) {
                    checkValue = newValue
                }

                if(parseInt(initialValues['delegates'][key.slice(key.indexOf("[") + 1, key.indexOf("]"))]) !== parseInt(checkValue)) {
                    initial = false;
                }
            }
        }
        if(removedRows) {
            for (const [key, value] of Object.entries(removedRows)) {

                let checkValue = value;
                if(key === name) {
                    checkValue = newValue
                }

                if(initialValues['remove'][key.slice(key.indexOf("[") + 1, key.indexOf("]"))] !== checkValue) {
                    initial = false;
                }

            }
        }

        if(initial){
            setFlag(false);
        }
    }



}

let handleForm = (values, submit, props, pristine, setSubmitting, setEnquiryVenueData, setError) => {
    return submit(values, props, pristine, {}).then((result) => {
        setSubmitting(false);
        setEnquiryVenueData(mapEnquiryVenueData(result.data));
    }).catch(error => {
        setSubmitting(false);
        setError(true);
    });
}

let checkBox = (input) => {
    return (
        <input type="checkbox" className={input.value ? '' : 'row-hidden'} name={input.name}
               checked={input.value ? false : true} onChange={(e) => input.onChange(!input.value)}/>
    );
}

let error = (meta, props) => {
    return <div>
        {meta.error ? <span {...props} className='bg-warning text-white p-3 mb-5 block'>{meta.error}</span> : ''}
    </div>
}

let validateForm = (values) => {
    const errors = {}
    let mustHaveAccommodationOrMeetingRoom = false;

    for (let key in values['remove']) {
        if (key.includes('accommodation_')) {
            if (!values['remove'][key]) {
                mustHaveAccommodationOrMeetingRoom = true;
            }
        }
        if (key.includes('meeting_')) {
            if (!values['remove'][key]) {
                mustHaveAccommodationOrMeetingRoom = true;
            }
        }
    }


    if (!mustHaveAccommodationOrMeetingRoom) {
        errors.accomodation_or_meeting_room = 'Must have either an accommodation or meeting room booked'
    }

    // console.log(values);
    // console.log(errors);
    return errors
}

let warnForm = (values) => {
    const warnings = {}



    for(let key in values['delegates']) {
        if(typeof values['minDelegates'][key] !== 'undefined') {
            if(values['delegates'][key] < values['minDelegates'][key]) {

                warnings[key] = 'You have entered a number lower than the minimum delegate count for this quoted element, so the venue will need to review costs';
            }
        }
    }

    // console.log(values);
    // console.log(errors);
    return warnings
}

export
{
    buildCurrencyFormatter,
    setInitialRemove,
    setInitialMinDelegates,
    setInitialDelegates,
    handleForm,
    checkBox,
    warnForm,
    error,
    validateForm,
    flagChanges

}
