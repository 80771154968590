// we're thunking here so we don't have to pass the whole form values state around the entirity of AS
// (avoiding re-renders on every input change)

import axios from "axios";
import { getFormValues } from "redux-form";
import queryString from "query-string";
import { omit } from "lodash";

export default (history, localStateSetter, v) => (d, getState) => {
  localStateSetter.loading();
  const values = { ...getFormValues("simple-search")(getState()) };
  if (!values.location) {
    localStateSetter.error("Please enter a location");
    return;
  }
  const type = values.venueType_id;
  delete values.venueType_id;
  if (type) {
    values.types = [type];
  }
  if (values.lat && values.lng) {
    values.location_type = "Map";
  }
  const params = {
    ...values,
    ...v,
  };
  Object.keys(params).forEach(v => {
    if (params[v] === "") delete params[v];
  });
  axios
    .get(`${window.NODE_AS}/`, {
      params,
    })
    .then(r => {
      localStateSetter.results(r.data.data, r.data.count);
      history.push( window.location.pathname + `?results=${r.data.count}&${queryString.stringify(
          omit(params, ["placeArea"])
        )}`
      );
    })
    .catch(err => {
      localStateSetter.error();
    });
};
