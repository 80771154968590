import React, { useState, useEffect } from "react";
import { Link, useLocation, withRouter } from "react-router-dom";
import { useSelector, connect, useDispatch } from "react-redux";
import {isAgencyAdmin, isAgent, isInternal, isVenueAdmin, token as tokenSelector, token} from "modules/auth";
import { currencyFormatter } from "utils";
import {
  ButtonBlack,
  ButtonBrand,
  ButtonSpan,
  ButtonPrimary, ButtonPrimaryRed,
} from "Components/Button";
import RfpProposalDetailModal from "./RfpProposalDetailModal";
import PackagePriceBreakdownModal from "./PackagePriceBreakdownModal";
import RoomsPriceBreakdownModal from "./RoomsPriceBreakdownModal";
import CateringPriceBreakdownModal from "./CateringPriceBreakdownModal";
import AccommodationPriceBreakdownModal from "./AccommodationPriceBreakdownModal";
import CommissionPriceBreakdownModal from "./CommissionPriceBreakdownModal";
import EnquiryStatus from "../../pages/admin/rfp/Confirmation/Components/EnquiryStatus";
import { fetchVatRates } from "../../redux/actions";
import { ENQUIRY_VENUE_DATA_STATUS } from "../../modules/rfp/enquiryVenueDataStatus";
import useService from "../../hooks/useService";
import { getEnquiryById } from "../../services/rfpService";
import AdditionalCostsPriceBreakdownModal from "./AdditionalCostsPriceBreakdownModal";
import { formatDate } from "../../modules/dates";

const Row = ({ dark, title, value }) => {
  return (
    <div
      className="p-2 flex justify-between items-center"
      style={{
        backgroundColor: dark ? "#eaeaea" : "#f9f9f9",
      }}
    >
      <span>{title}</span>
      <span className="font-bold">{value}</span>
    </div>
  );
};

const ApprovedSlide = ({
                         brief,
                         venueConfirmed,
                         enquiry,
                         confirmed,
                         availabilityTypes,
                         data,
                         onConfirmVenue,
                         onExport,
                         onRemove,
                         onRequestSiteVisit,
                         onViewMessages,
                         venue,
                         vatRates = [],
                         isManaged,
                         publicView = false,
                       }) => {
  const [modal, setModal] = useState(null);
  const [packageModal, setPackageModal] = useState(null);
  const [roomsModal, setRoomsModal] = useState(null);
  const [equipmentsModal, setEquipmentsModal] = useState(null);
  const [additionalCostingsModal, setAdditionalCostingsModal] = useState(null)
  const [commissionModal, setCommissionModal] = useState(null);
  const [accommodationModal, setAccommodationModal] = useState(null);
  const [cateringModal, setCateringModal] = useState(null);
  const location = useLocation();

  const agent = useSelector(isAgent);
  const agentAdmin = useSelector(isAgencyAdmin);
  const internal = useSelector(isInternal);
  const availability = availabilityTypes.find(
    item => item.value === data.venue_initial_response.availability_type_id,
  );
  const availabilityNote = data.venue_initial_response.availability_type_note;
  const summaryItems = data.proposal_data.summary.summary_items || [];
  const total = summaryItems.find(item => item.step_id === 9) || {};
  const packages = summaryItems.find(item => item.step_id === 2) || {};
  const equipments = summaryItems.find(item => item.step_id === 4) || {};
  const catering = summaryItems.find(item => item.step_id === 5) || {};
  const accommodation = summaryItems.find(item => item.step_id === 6) || {};
  const rooms = summaryItems.find(item => item.step_id === 3) || {};
  const additionalItems = summaryItems.find(item => item.step_id === 10)  || {}
  const totals = summaryItems.find(item => item.step_id === 9) || {};
  const commissionTotals = data.proposal_data.commission_items.find(item => item.step_id === 9) || {};
  let commissionPercentage = null;
  let commissionTotal = commissionTotals.total_commission;
  if (typeof commissionTotals.total_commission !== "undefined") {
    commissionPercentage = (commissionTotals.total_commission / commissionTotals.total_cost_before_commission) * 100;
  }
  const viewMessages = true;

  const token = useSelector(tokenSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchVatRates(token));
  }, [dispatch]);

  const mappedVatRates = vatRates.length > 0 ? vatRates.map(vat_rate => ({
    value: vat_rate.id,
    text: vat_rate.rate + "%",
    number: vat_rate.rate,
  })) : [];


  const canConfirm = () => {
    if (confirmed.includes(venue.id)) {
      return false;
    }
    if (
      data.venue_confirmation &&
      data.venue_confirmation.date_user_confirmed
    ) {
      return false;
    }
    return true;
  };

  const hasOneConfirmed = (ignoreRoleCheck = false) => {
    let hasOneConfirmed = false;
    enquiry.enquiry_venue_datas.forEach(evd => {
      if(evd.status === ENQUIRY_VENUE_DATA_STATUS.USER_CONFIRMED){
        hasOneConfirmed = true;
      }
    })
    if(!ignoreRoleCheck)
    {
      if(agentAdmin ) {
        hasOneConfirmed = false;
      }
    }
    return hasOneConfirmed;
  }
  return (
    <>
      <div className="mb-5">
        <h2
          className="text-white bg-black font-normal font-body p-3 uppercase"
          style={{ minHeight: "6.7rem" }}
        >
          {venue.name}
        </h2>
        <p
          className="bg-brand text-white font-normal uppercase text-lg flex items-center p-3"
          style={{ minHeight: "5rem" }}
        >
          <EnquiryStatus status={data.status} />
        </p>
        <div
          className="re-16/9 w-full bg-no-repeat bg-cover bg-center"
          style={
            venue.image
              ? {
                backgroundImage: `url(${window.API}/venues/${venue.slug}/images/${venue.image})`,
              }
              : null
          }
        />
        <div className="flex justify-between p-3 font-normal text-xl mb-2">
          <div>
            <i className="mr-2 fas fa-users" />
            {venue.capacity}
          </div>
          <div>
            <i className="mr-2 fas fa-bed" />
            {venue.bedrooms}
          </div>
          <div>
            <i className="mr-2 fas fa-handshake" />
            {venue.meeting_rooms}
          </div>
        </div>
        <div className="p-3 flex justify-between items-center bg-black text-white">
          <span className="font-normal">OPTION</span>
          <span className="text-lg">
            {availability
              ? availability.text
                .split(" -")[0]
                .replace(
                  "Cannot do dates, will offer alternative",
                  "Alternative dates",
                )
                .replace("Not enough syndicate rooms", "Not enough rooms")
                .replace(
                  "Insufficient Bedrooms available",
                  "Insufficient bedrooms",
                )
              : ""}{" "}
            <ButtonSpan
              className="text-base font-normal underline ml-2"
              onClick={() => setModal("availability")}
            >
              view
            </ButtonSpan>
          </span>
        </div>
        <div className="p-3 bg-brand  bg-black text-white">
          <div className={'flex justify-between items-center py-1'}>
            <span className="font-normal">PROPOSAL PRICE</span>
            <span className="font-bold text-xl">
            {currencyFormatter(data.currency.currency_code).format(total.total_inc_vat)}{" "}
              <ButtonSpan
                  className="text-base font-normal underline ml-2"
                  onClick={() => setModal("price")}
              >
              view
            </ButtonSpan>
            </span>
          </div>
          {total.reduced_from_inc_vat > total.total_inc_vat && (total.reduced_from_inc_vat - total.total_inc_vat) > 0 ?
              <div>
                <hr className={'mt-1 mb-2'}/>
                <div className={'flex justify-between items-center mb-1'}>
                  <span className="font-normal">REDUCED FROM</span>
                  <span className="font-bold text-xl" style={{paddingRight: '45px'}}>{currencyFormatter(data.currency.currency_code).format(total.reduced_from_inc_vat)}{" "}</span>
                </div>
                <div className={'flex justify-between items-center mb-1'}>
                  <span className="font-normal">COST SAVING</span>
                  <span className="font-bold text-xl" style={{paddingRight: '45px'}}>{currencyFormatter(data.currency.currency_code).format(total.reduced_from_inc_vat - total.total_inc_vat)}{" "}</span>
                </div>

              </div>
              :
              null
          }
          <span className="font-normal" style={{fontSize: '14px'}}>Prices include {data.currency.currency_code === 'GBP' ? `VAT` : `Tax`}</span>
        </div>

        <div className="p-3">PRICE BREAKDOWN</div>
        {data.proposal_data.holding_until_date ?
          <Row
            title="Will Hold Until"
            value={formatDate(data.proposal_data.holding_until_date,'ddd Do MMM YYYY')}
          /> : null
        }
        {data.proposal_data.hold_for ?
          <Row
            title="Will Hold for"
            value={data.proposal_data.hold_for + " day(s)"}
          /> : null
        }
        {data.proposal_data.conference_packages.length > 0 ?
          <Row
            title="Conference Packages"
            value={
              packages.total_inc_vat ? (
                <span className="font-bold text-lg">
                {!!parseInt(packages.total_inc_vat) && currencyFormatter(data.currency.currency_code).format(packages.total_inc_vat)}
                  {!!parseInt(packages.total_inc_vat) && (
                    <ButtonSpan
                      className="text-base font-normal underline ml-2"
                      onClick={() => setPackageModal(true)}
                    >
                      view
                    </ButtonSpan>
                  )}
              </span>
              ) : null
            }
          /> : null}
        {data.proposal_data.supplementary_items.length > 0 ?
          <Row
            dark
            title="Catering"
            value={
              catering.total_inc_vat ? (
                <span className="font-bold text-lg">
                {!!parseInt(catering.total_inc_vat) && currencyFormatter(data.currency.currency_code).format(catering.total_inc_vat)}
                  {!!parseInt(catering.total_inc_vat) && (
                    <ButtonSpan
                      className="text-base font-normal underline ml-2"
                      onClick={() => setCateringModal(true)}
                    >
                      view
                    </ButtonSpan>
                  )}
              </span>
              ) : null
            }
          />

          : null}
        {data.proposal_data.accommodations.length > 0 ?

          <Row
            title="Accommodation"
            value={
              accommodation.total_inc_vat ? (
                <span className="font-bold text-lg">
                {!!parseInt(accommodation.total_inc_vat) && currencyFormatter(data.currency.currency_code).format(accommodation.total_inc_vat)}
                  {!!data.proposal_data.accommodations.length && (
                    <ButtonSpan
                      className="text-base font-normal underline ml-2"
                      onClick={() => setAccommodationModal(true)}
                    >
                      view
                    </ButtonSpan>
                  )}
              </span>
              ) : null
            }
          />

          : null}
        {data.proposal_data.meeting_rooms.length > 0 ?
          <Row
            dark
            title="Event Spaces"
            value={
              rooms.total_inc_vat ? (
                <span className="font-bold text-lg">
                {!!parseInt(rooms.total_inc_vat) && currencyFormatter(data.currency.currency_code).format(rooms.total_inc_vat)}
                  {!!data.proposal_data.meeting_rooms.length && (
                    <ButtonSpan
                      className="text-base font-normal underline ml-2"
                      onClick={() => setRoomsModal(true)}
                    >
                      view
                    </ButtonSpan>
                  )}
              </span>
              ) : null
            }
          />
          : null}

        {data.proposal_data.equipment.length > 0 ?
          <Row

            title="Equipment"
            value={
              equipments.total_inc_vat ? (
                <span className="font-bold text-lg">
                {!!parseInt(equipments.total_inc_vat) && currencyFormatter(data.currency.currency_code).format(equipments.total_inc_vat)}
                  {!!data.proposal_data.equipment.length && (
                    <ButtonSpan
                      className="text-base font-normal underline ml-2"
                      onClick={() => setEquipmentsModal(true)}
                    >
                      view
                    </ButtonSpan>
                  )}
              </span>
              ) : null
            }
          /> : null}

        {data.proposal_data.additional_items.filter((ai) => {
          return ai.is_in_proposal_costs
        } ).length > 0 ?
            <Row

                title="Additional Costings"
                value={
                  additionalItems.total_inc_vat ? (
                      <span className="font-bold text-lg">
                {!!parseInt(additionalItems.total_inc_vat) && currencyFormatter(data.currency.currency_code).format(additionalItems.total_inc_vat)}
                        {!!data.proposal_data.additional_items.filter((ai) => {
                          return ai.is_in_proposal_costs
                        }).length && (
                            <ButtonSpan
                                className="text-base font-normal underline ml-2"
                                onClick={() => setAdditionalCostingsModal(true)}
                            >
                              view
                            </ButtonSpan>
                        )}
              </span>
                  ) : null
                }
            /> : null}
        {(agent || internal) && (
          <Row
            title="Commission % if Agent"
            value={commissionPercentage != null ? (
              <span className="font-bold text-lg">
                  {!!parseInt(commissionTotal) && currencyFormatter(data.currency.currency_code).format(commissionTotal)}
                <ButtonSpan
                  className="text-base font-normal underline ml-2"
                  onClick={() => setCommissionModal(true)}
                >
                    view
                  </ButtonSpan>
                </span>
            ) : null}
          />
        )}
        {!publicView && (
          <ButtonBlack
            classes="p-3 flex items-center w-full mb-3"
            onClick={() => onExport(venue)}
            style={{ height: "auto", lineHeight: 1 }}
          >
            <i className="fas fa-share mr-2"></i>Export
          </ButtonBlack>
        )}
        <div className="sm:flex sm:flex-wrap">
          {!publicView && (data.site_visit || (isManaged && !agent)) ? (
            <ButtonBlack
              Element={Link}
              classes="p-3 w-full mb-1"
              target="_blank"
              to={`/venue/${venue.slug}`}
              rel="noopener noreferrer"
              style={{ height: "auto", lineHeight: 1 }}
            >
              View venue
            </ButtonBlack>
          ) : (
            <>
              {!publicView && (
                <>
                  <div className="sm:w-1/2">
                    <ButtonBlack
                      Element={Link}
                      classes="p-3 w-full sm:mr-1 mb-1"
                      target="_blank"
                      to={`/venue/${venue.slug}`}
                      rel="noopener noreferrer"
                      style={{ height: "auto", lineHeight: 1 }}
                    >
                      View venue
                    </ButtonBlack>
                  </div>
                  <div className="sm:w-1/2 sm:pl-1">
                    <ButtonBlack
                      classes="p-3 w-full mb-1"
                      onClick={() => onRequestSiteVisit(venue)}
                      style={{ height: "auto", lineHeight: 1 }}
                    >
                      Site visit
                    </ButtonBlack>
                  </div>
                </>
              )}
            </>
          )}
          {canConfirm() && !isManaged && !publicView && (
            <>
              {data.status < ENQUIRY_VENUE_DATA_STATUS.USER_AMENDS ?
                <ButtonPrimary
                  classes="p-3 w-full mb-1"
                  onClick={() => onConfirmVenue(venue)}
                  style={{ height: "auto", lineHeight: 1 }}
                >
                  I'm interested but....
                </ButtonPrimary>
                : null}

              {canConfirm() && !hasOneConfirmed() && !isManaged && (agent || internal) && data.status === ENQUIRY_VENUE_DATA_STATUS.USER_CONFIRMED ?

                <Link to={{ pathname: `${location.pathname}/confirm/${data.id}` }} className={"w-full"}>
                  <ButtonBrand
                    classes="p-3 w-full mb-1"

                    style={{ height: "auto", lineHeight: 1 }}
                  >
                    Confirm Booking
                  </ButtonBrand>
                </Link>
                : null}
              {canConfirm() && !hasOneConfirmed() && !isManaged && !agent && !internal && data.status === ENQUIRY_VENUE_DATA_STATUS.CHECKSHEET_DATA_RECEIVED ?

                <Link to={{ pathname: `${location.pathname}/confirm/${data.id}` }} className={"w-full"}>
                  <ButtonBrand
                    classes="p-3 w-full mb-1"

                    style={{ height: "auto", lineHeight: 1 }}
                  >
                    Confirm Booking
                  </ButtonBrand>
                </Link>
                : null}

            </>
          )}

          {canConfirm() &&  !hasOneConfirmed() && (isManaged && agent) && (
            <>
              {data.status < ENQUIRY_VENUE_DATA_STATUS.USER_AMENDS ?
                <ButtonPrimary
                  classes="p-3 w-full mb-1"
                  onClick={() => onConfirmVenue(venue)}
                  style={{ height: "auto", lineHeight: 1 }}
                >
                  I'm interested but....
                </ButtonPrimary>
                : null}
              <Link
                to={!data.venue_confirmation && data.status >= ENQUIRY_VENUE_DATA_STATUS.CHECKSHEET_DATA_RECEIVED && data.status < ENQUIRY_VENUE_DATA_STATUS.USER_CONFIRMED ? `/admin/rfp/${data.rfp_enquiry_id}/confirm-client/${data.id}` : { pathname: `${location.pathname}/confirm/${data.id}` }}
                className={"w-full"}>
                { hasOneConfirmed(true) && !data.venue_confirmation && data.status >= ENQUIRY_VENUE_DATA_STATUS.CHECKSHEET_DATA_RECEIVED && data.status < ENQUIRY_VENUE_DATA_STATUS.USER_CONFIRMED ?
                    <ButtonPrimaryRed
                        classes="p-3 w-full mb-1"

                        style={{ height: "auto", lineHeight: 1 }}
                    >

                          Confirm on behalf of Client
                    </ButtonPrimaryRed>
                    :
                    <ButtonBrand
                        classes="p-3 w-full mb-1"

                        style={{ height: "auto", lineHeight: 1 }}
                    >
                        Confirm Booking
                    </ButtonBrand>
                }

              </Link>

            </>

          )}
          {viewMessages && !isManaged && (
            <ButtonPrimary
              classes="p-3 w-full mb-1"
              onClick={() => onViewMessages(venue)}
              style={{ height: "auto", lineHeight: 1 }}
            >
              View Messages
            </ButtonPrimary>
          )}
          {!canConfirm() && !publicView && (
            <Link to={{ pathname: `${location.pathname}/confirm/${data.id}` }} className={"w-full"}>
              <ButtonBrand
                classes="p-3 w-full mb-1"

                style={{ height: "auto", lineHeight: 1 }}
              >
                {(isManaged && agent) && data.status < ENQUIRY_VENUE_DATA_STATUS.VENUE_CONFIRMED ? "Confirm on behalf of Venue" : "View Confirmed Details"}
              </ButtonBrand>
            </Link>
          )}
          {publicView || (isManaged && !agent) ?
            null
            : <ButtonSpan
              className="p-3 text-center block w-full mb-2"
              onClick={() => onRemove(venue)}
              style={{ height: "auto", lineHeight: 1 }}
            >
              <i className="mr-3 fal fa-minus-circle"></i>
              Remove Venue
            </ButtonSpan>}

        </div>
      </div>
      {modal && (
        <RfpProposalDetailModal
          onClose={() => setModal(null)}
          availability={availability}
          availabilityNote={availabilityNote}
          data={data}
          venue={venue}
          type={modal}
        />
      )}
      {packageModal && (
        <PackagePriceBreakdownModal
          brief={brief}
          vatRates={mappedVatRates}
          attachments={data.proposal_data.attachments.filter(
            item => item.attachment_type_id === 1,
          )}
          currency={data.currency}
          notes={data.proposal_data.packages_notes}
          rooms={venue.rooms || []}
          onClose={() => setPackageModal(null)}
          packages={data.proposal_data.conference_packages}
          total={data.proposal_data.summary.summary_items.find(
            item => item.step_id === 2,
          )}
          enquiryVenueData={data}
        />
      )}
      {roomsModal && (
        <RoomsPriceBreakdownModal
          brief={brief}
          vatRates={mappedVatRates}
          attachments={data.proposal_data.attachments.filter(
            item => item.attachment_type_id === 2,
          )}
          notes={data.proposal_data.meeting_rooms_notes}
          rooms={venue.rooms || []}
          equipment={[]}
          venueId={venue.id}
          onClose={() => setRoomsModal(null)}
          meetingRooms={data.proposal_data.meeting_rooms || []}
          total={data.proposal_data.summary.summary_items.find(
            item => item.step_id === 3,
          )}
          currency={data.currency}
          enquiryVenueData={data}
        />
      )}

      {equipmentsModal && (
        <RoomsPriceBreakdownModal
          vatRates={mappedVatRates}
          title={"Equipment"}
          attachments={[]}
          notes={[]}
          rooms={[]}
          equipment={data.proposal_data.equipment || []}
          venueId={venue.id}
          onClose={() => setEquipmentsModal(null)}
          meetingRooms={[]}
          currency={data.currency}
          total={data.proposal_data.summary.summary_items.find(
            item => item.step_id === 4,
          )}
        />
      )}

      {additionalCostingsModal && (
          <AdditionalCostsPriceBreakdownModal
              vatRates={mappedVatRates}
              title={"Additional Costs"}
              additionalCosts={data.proposal_data.additional_items.filter((ai) => {
                return ai.is_in_proposal_costs
              } ) || []}
              venueId={venue.id}
              onClose={() => setAdditionalCostingsModal(null)}
              currency={data.currency}
              total={data.proposal_data.summary.summary_items.find(
                  item => item.step_id === 10,
              )}
          />
      )}

      {commissionModal && (
        <CommissionPriceBreakdownModal
          venueId={venue.id}
          onClose={() => setCommissionModal(null)}
          commissionItems={data.proposal_data.commission_items || []}
          summaryItems={summaryItems || []}
          currency={data.currency}
        />
      )}
      {cateringModal && (
        <CateringPriceBreakdownModal
          vatRates={mappedVatRates}
          attachments={data.proposal_data.attachments.filter(
            item => item.attachment_type_id === 3,
          )}
          currency={data.currency}
          notes={data.proposal_data.catering_notes}
          data={data.proposal_data}
          rooms={venue.rooms || []}
          catering={data.proposal_data.supplementary_items || []}
          additional={data.proposal_data.additional_items || []}
          venueId={venue.id}
          onClose={() => setCateringModal(null)}
          total={data.proposal_data.summary.summary_items.find(
            item => item.step_id === 5,
          )}
        />
      )}
      {accommodationModal && (
        <AccommodationPriceBreakdownModal
          vatRates={mappedVatRates}
          attachments={data.proposal_data.attachments.filter(
            item => item.attachment_type_id === 4,
          )}
          currency={data.currency}
          notes={data.proposal_data.accommodation_notes}
          data={data.proposal_data}
          accommodation={data.proposal_data.accommodations || []}
          onClose={() => setAccommodationModal(null)}
          total={data.proposal_data.summary.summary_items.find(
            item => item.step_id === 6,
          )}
        />
      )}
    </>
  );
};

let ApprovedSlideWithState = connect((state, props) => {
  return {
    vatRates: state.vatRate.vatRates,
  };

})(ApprovedSlide);

export default withRouter(ApprovedSlideWithState);
