import axios from 'axios';
import handleErrors from './utils';
import {
  COMPANY_LIST_ERROR,
  COMPANY_LIST_EVENTS_ERROR,
  COMPANY_LIST_EVENTS_RECEIVED,
  COMPANY_LIST_EVENTS_REQUEST,
  COMPANY_LIST_RECEIVED,
  COMPANY_LIST_REQUEST,
  COMPANY_LIST_UNLOAD,
  COMPANY_RECEIVED,
  COMPANY_RECEIVED_ERROR,
  COMPANY_REQUEST,
  COMPANY_ROLES_UPDATE_RECEIVED_REQUEST,
  COMPANY_UNLOAD,
  COMPANY_USER_LIST_RECEIVED_ERROR,
  COMPANY_USER_LIST_REQUEST,
  COMPANY_USER_LIST_REQUEST_RECEIVED,
  COMPANY_USER_LIST_UNLOAD,
  CREATE_COMPANY,
  CREATE_COMPANY_ERROR,
  DELETE_COMPANY,
  DELETE_COMPANY_ERROR,
  DELETE_COMPANY_USER,
  DELETE_COMPANY_USER_ERROR,
  EDIT_COMPANY,
  EDIT_COMPANY_ERROR,
  EDIT_COMPANY_USER,
  EDIT_COMPANY_USER_ERROR,
  DISABLE_COMPANY_USER_REQUEST,
  DISABLE_COMPANY_USER_REQUEST_RECEIVED,
  DISABLED_COMPANY_USER_REQUEST_ERROR
} from '../reducers/types';
import * as ROUTES from '../../Router/corporate_routes';
import {
  rolesUpdateReceivedError,
  userRolesUpdateRequest
} from "./roles";
import {removeEmptySearchParams} from "../../utils";

/** Companies */
export const companyCreated = (data) => ({
  type: CREATE_COMPANY,
  data
});

export const companyCreatedError = (error) => ({
  type: CREATE_COMPANY_ERROR,
  error
});

export const companyEdit = (data) => ({
  type: EDIT_COMPANY,
  data
});

export const companyEditError = (error) => ({
  type: EDIT_COMPANY_ERROR,
  error
});

export const companyUserEdit = (data) => ({
  type: EDIT_COMPANY_USER,
  data
});

export const companyUserEditError = (error) => ({
  type: EDIT_COMPANY_USER_ERROR,
  error
});

export const companyUserDelete = (data) => ({
  type: DELETE_COMPANY_USER,
  data
});

export const companyUserDeleteError = (error) => ({
  type: DELETE_COMPANY_USER_ERROR,
  error
});

export const companyUserListRequest = () => ({
  type: COMPANY_USER_LIST_REQUEST
});

export const companyUserListRequestReceived = data => ({
  type: COMPANY_USER_LIST_REQUEST_RECEIVED,
  data
});

export const companyUserListReceivedError = error => ({
  type: COMPANY_USER_LIST_RECEIVED_ERROR,
  error
});

export const companyUserListUnload = () => ({
  type: COMPANY_USER_LIST_UNLOAD,
});

export const companyListReceivedRequest = (data) => ({
  type: COMPANY_LIST_RECEIVED,
  data
});

export const companyListEventsReceivedRequest = (data) => ({
  type: COMPANY_LIST_EVENTS_RECEIVED,
  data
});

export const companyListRequest = () => ({
  type: COMPANY_LIST_REQUEST
});

export const companyListEventsRequest = () => ({
  type: COMPANY_LIST_EVENTS_REQUEST
});


export const companyListReceivedError = (error) => ({
  type: COMPANY_LIST_ERROR,
  error
});

export const companyListEventsReceivedError = (error) => ({
  type: COMPANY_LIST_EVENTS_ERROR,
  error
});

export const companyRequest = () => ({
  type: COMPANY_REQUEST
});

export const companyDelete = (data) => ({
  type: DELETE_COMPANY,
  data
});

export const companyDeleteError = (error) => ({
  type: DELETE_COMPANY_ERROR,
  error
});

export const companyReceivedRequest = (data) => ({
  type: COMPANY_RECEIVED,
  data
});

export const companyReceivedError = (error) => ({
  type: COMPANY_RECEIVED_ERROR,
  error
});

export const companyUnload = () => ({
  type: COMPANY_UNLOAD
});

export const unloadCompanyList = () => ({
  type: COMPANY_LIST_UNLOAD
});
export const companyRolesUpdateReceivedRequest = (data) => ({
  type: COMPANY_ROLES_UPDATE_RECEIVED_REQUEST,
  data
});

export const disableCompanyUserRequest = () => ({
  type: DISABLE_COMPANY_USER_REQUEST
});

export const disableCompanyUserRequestReceived = data => ({
  type: DISABLE_COMPANY_USER_REQUEST_RECEIVED,
  data
});

export const disableCompanyUserRequestError = error => ({
  type: DISABLED_COMPANY_USER_REQUEST_ERROR,
  error
});


/** Companies */
export const createCompany = (formValues, token) => {
  return (dispatch) => {
    dispatch(companyRequest());
    return axios.post(window.API + ROUTES.API.COMPANY.LIST, formValues, {
      headers: {Authorization: `Bearer ${token}`},
    })
        .then(response => {
          dispatch(companyCreated(response))
        })
        .catch(error => {
          dispatch(companyCreatedError(error));
          handleErrors(error);
          return Promise.reject(error);
        });
  };
};
export const fetchCompany = (id, token) => {
  return (dispatch) => {
    dispatch(companyRequest());
    return axios.get(window.API + ROUTES.API.COMPANY.SINGLE.replace(':id', id), {
      headers: {Authorization: `Bearer ${token}`},
    }).then(response => dispatch(companyReceivedRequest(response)))
        .catch(error => dispatch(companyReceivedError(error)));
  }
}
export const editCompany = (id, formValues, token) => {
  return (dispatch) => {
    dispatch(companyRequest());
    return axios.post(window.API + ROUTES.API.COMPANY.SINGLE.replace(':id', id), formValues, {
      headers: {Authorization: `Bearer ${token}`},
    }).then(response =>
        dispatch(companyEdit(response)))
        .catch(error => {
          dispatch(companyEditError(error));
          handleErrors(error);
        });
  }
}
export const deleteCompany = (id, token) => {
  return (dispatch) => {
    dispatch(companyDelete());
    return axios.delete(window.API + ROUTES.API.COMPANY.SINGLE.replace(':id', id), {
      headers: {Authorization: `Bearer ${token}`},
    }).then(
        response => dispatch(companyDelete(response),
            window.location.href = ROUTES.FRONTEND.COMPANY.ALL
        ))
        .catch(error => dispatch(companyDeleteError(error)))
  }
}
export const fetchCompanies = (token, page = 1, queryParams = {}, noPagination = false) => {
  if (!queryParams.page && !noPagination) {
    queryParams.page = page;
  }
  queryParams = new URLSearchParams(queryParams);
  removeEmptySearchParams(queryParams);
  let queryString = queryParams.toString()
  return (dispatch) => {
    dispatch(companyListRequest());
    return axios.get(window.API + ROUTES.API.COMPANY.LIST + "?" + queryString, {
      headers: {Authorization: `Bearer ${token}`},
    }).then(response => dispatch(companyListReceivedRequest(response.data)))
        .catch(error => dispatch(companyListReceivedError(error)));
  }
}

export const fetchCompaniesForEvents = (token) => {
  return (dispatch) => {
    dispatch(companyListEventsRequest());
    return axios.get(window.API + ROUTES.API.COMPANY.LIST_EVENTS, {
      headers: {Authorization: `Bearer ${token}`},
    }).then(response => dispatch(companyListEventsReceivedRequest(response.data)))
        .catch(error => dispatch(companyListEventsReceivedError(error)));
  }
}
export const editCompanyUser = (email, formValues, token) => {
  return (dispatch) => {
    dispatch(companyRequest());
    return axios.post(window.API + ROUTES.API.USER.SINGLE.replace(':email', email), formValues, {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => {
      dispatch(companyUserEdit(response));
    })
      .catch(error => dispatch(companyUserEditError(error)));
  };
};
export const deleteCompanyUser = (email, formValues, token) => {
  return (dispatch) => {
    dispatch(companyRequest());
    return axios.post(window.API + ROUTES.API.USER.SINGLE.replace(':email', email), formValues, {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => {
      dispatch(companyUserDelete(response));
    })
      .catch(error => dispatch(companyUserDeleteError(error)));
  };
};

export const giveCompanyUserRoles = (formValues, token) => {
  return (dispatch) => {
    dispatch(userRolesUpdateRequest());
    return axios.post(window.API + ROUTES.API.ROLES.UPDATE, formValues, {
      headers: {Authorization: `Bearer ${token}`},
    }).then(response => {
      dispatch(companyRolesUpdateReceivedRequest(response.data))
    })
      .catch(error => dispatch(rolesUpdateReceivedError(error)));
  }
}

export const giveCompanyUserCorporateAccess = (formValues, token) => {
  return (dispatch) => {
    dispatch(userRolesUpdateRequest());
    return axios.post(window.API + ROUTES.API.ROLES.GIVE_CORPORATE_ACCESS, formValues, {
      headers: {Authorization: `Bearer ${token}`},
    }).then(response => {
      dispatch(companyRolesUpdateReceivedRequest(response.data))
    })
      .catch(error => dispatch(rolesUpdateReceivedError(error)));
  }
}

export const revokeCompanyUserRoles = (formValues, token) => {
  return (dispatch) => {
    dispatch(userRolesUpdateRequest());
    return axios.post(window.API + ROUTES.API.ROLES.REVOKE, formValues, {
      headers: {Authorization: `Bearer ${token}`},
    }).then(response => {
      dispatch(companyRolesUpdateReceivedRequest(response.data))
    })
      .catch(error => dispatch(rolesUpdateReceivedError(error)));
  }
}

export const revokeCompanyUserCorporateAccess = (formValues, token) => {
  return (dispatch) => {
    dispatch(userRolesUpdateRequest());
    return axios.post(window.API + ROUTES.API.ROLES.REVOKE_CORPORATE_ACCESS, formValues, {
      headers: {Authorization: `Bearer ${token}`},
    }).then(response => {
      dispatch(companyRolesUpdateReceivedRequest(response.data))
    })
        .catch(error => dispatch(rolesUpdateReceivedError(error)));
  }
}

export const fetchCompanyUsers = (id, token) => {
  return (dispatch) => {
    dispatch(companyUserListRequest());
    return axios.get(window.API + ROUTES.API.COMPANY.USER.LIST.replace(':id', id), {
      headers: {Authorization: `Bearer ${token}`}
    }).then(response => {
      dispatch(companyUserListRequestReceived(response.data))
    }).catch(error => dispatch(companyUserListReceivedError(error)))
  }
}

export const updateCorporateRolesForUser = (user, roles, token) => {
  return (dispatch) => {
    return axios.post(window.API + '/corporate-roles/update', {user: user, roles: roles},  {
      headers: {Authorization: `Bearer ${token}`},
    }).then(response => dispatch(companyEdit(response)))
        .catch(error => dispatch(companyEditError(error)));
  }
}

export const disableCompanyUserByToken = (formValues, token) => {
  return (dispatch) => {
    dispatch(disableCompanyUserRequest());
    return axios.post(window.API + ROUTES.API.COMPANY.USER.DISABLE_BY_TOKEN, formValues, {
      headers: {Authorization: `Bearer ${token}`}
    }).then(response => {
      dispatch(disableCompanyUserRequestReceived(response.data))
      window.location.reload();
    }).catch(error => dispatch(disableCompanyUserRequestError(error)))
  }
}
