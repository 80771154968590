module.exports = {
  transparent: "transparent",
  black: "#2d2d2d",
  "light-black": "#4c4c4c",
  "dark-grey": "#6a6a6a",
  "grey-md": "#cdcdcd",
  brand: "#99cc33",
  primary: "rgb(0, 153, 255)",
  grey: "rgb(229, 234, 241)",
  "light-text": "#888",
  "off-white": "#eeeeee",
  "dull-white": "#DEDEB9",
  white: "#ffffff",
  warning: "#9c0606",
  red: "#e50000",
  success: "green",
  orange: "#e65100",
  green: "#9BCB41",
  "dark-green": "#5D801C",
  "light-grey": "#f7f7f7",
  "black-transparent": "rgba(0,0,0,0.4)",
  "dark-blue": "rgb(89, 128, 214)",
  blue: "#1A9BFC",
  "light-blue-grey": "#E5EAF1",
  "light-blue": "#F3FAFF",
  "light-off-blue": "#C9E8FF",
  "edge-blue": "#5980D6",
  "grey-darker": "#838383",
  "lighter-blue": "#ebf6fe",
  "dark-red": "#993522",
  yellow: "#FBDD40",
  "blue-light": "#b5dbfe",
};
