// import get from "lodash/fp/get";
import get from "lodash/get";
import anyPass from "lodash/fp/anyPass";

/**
 * Getters
 */

// auth:Object -> [role:String]
const getRoles = auth => get(auth, "role", "").split(":");
const getPermissions = auth => get(auth, "permission", "").split(":")

// role:String -> auth:Object -> Boolean
const hasRole = role => auth => getRoles(auth).includes(role);
const hasPermission = permission => auth => getPermissions(auth).includes(permission);

export const hasVenues = auth =>
  Array.isArray(auth.venues) && auth.venues.length > 0;

export const hasPaidVenues = auth =>
  Array.isArray(auth.venues) &&
  auth.venues.filter(v => v.temp_permission === "paid").length > 0;

export const hasPremiumVenues = auth =>
  Array.isArray(auth.venues) &&
  auth.venues.filter(v => v.temp_permission === "premium").length > 0;

const getCompany = auth => {
  return (auth?.company ?? auth?.companyObject) ?? false;
}

const getCorporateAccount = auth => {
  return (auth?.company?.corporate_account ?? auth?.corporateAccount) ?? false;
}

export const hasCorporateAccount = auth => {
  // console.log({a: auth})
  return !!getCorporateAccount(auth);
}

export const hasCorporateKpiDocument = auth => {
  return !!getCorporateAccount(auth)?.kpi_document
}

export const hasActiveCorporateAccount = auth => {
  // console.log({a: auth})
  return getCorporateAccount(auth)?.is_active;
}
export const hasCorporateAccess = auth => {
  return !!auth?.user?.has_corporate_access;
}
export const corporateAccountId = auth => {
  // console.log({a: auth})
  // console.log(auth)
  return getCorporateAccount(auth)?.id ?? false;
}

export const corporateAccountObject = auth => {
  // console.log({a: auth})
  return getCorporateAccount(auth);
}


export const companyObject = auth => {
  // console.log({a: auth})
  return getCompany(auth); //.company ? auth.company : {};
}

export const agencyObject = auth => {
  return auth.agency && (typeof auth.agency !== undefined) ? auth.agency : {};
}

export const hasLicensedVenues = anyPass([hasPaidVenues, hasPremiumVenues]);

/**
 * Has Role Selectors
 * auth:Object -> Boolean
 */

export const isRoleSuperAdmin = hasRole("super-admin");
export const isRoleEdgeAdmin = hasRole("edge-admin");
export const isRoleJuniorAgent = hasRole("junior_agency_user");
export const isRoleTrinity = hasRole("trinity");
export const isRoleTrinityUser = hasRole("trinity-user");
export const isRoleTrinityEditor = hasRole("trinity-editor");
export const isMarketingManager = hasRole("marketing-manager");
export const isRoleAccountManager = hasRole("account-manager");
export const isRoleTrinityEdgeRFP = hasRole('trinity-edge-rfp');
export const isVenueRFPResponder = hasRole('venue-rfp-responder');
export const isSuperAgent = hasRole('super_agent');
export const isAgencyAdmin = hasRole('agency_administrator');
export const isAgencyUser = hasRole('agency_user');
export const isCompanyAdmin = hasRole('company_administrator');
export const isCompanyUser = hasRole('company_user');
export const isHeadOfProcurement = hasRole('head_of_procurement');
export const isHeadOfEvents = hasRole('head_of_events');
export const isNonEventSpecialist = hasRole('non_event_specialist');
export const isEventSpecialist = hasRole('event_specialist');


export const isRoleSuperAdminOrTrinityEditor = anyPass([
  isRoleSuperAdmin,
  isRoleTrinityEditor,
  isRoleEdgeAdmin,
]);

export const canSeeAnalytics = auth => {
  return (isInternal(auth) || hasLicensedVenues(auth) || isRoleTrinityUser(auth) || isRoleAccountManager(auth)) && hasPermission('read_analytics')(auth);
}

export const canSeeSubscriptions = auth => {
  return (isInternal(auth) || isRoleAccountManager(auth) || isRoleTrinityUser(auth)) && hasPermission('read_subscriptions')(auth);
}
export const canCreateSubscriptions = auth => (canSeeSubscriptions(auth) && hasPermission('create_subscriptions')(auth));

export const canEditVenue = hasPermission('update_venue');
export const canCreateVenue = hasPermission('create_venue');
export const canEditUsers = hasPermission('update_user');
export const canEditRoles = hasPermission('update_laravel_roles');
export const canCreateOwnChecksheet = hasPermission('can_create_own_checksheet');
export const canCreateAllChecksheets = hasPermission('can_create_all_checksheets');
export const canReadChecksheet = hasPermission('can_read_checksheet');
export const canCreateRfp = hasPermission('can_create_rfp');
export const canReadRfp = hasPermission('can_read_rfp');
export const canManageRfp = hasPermission('can_manage_rfp');
export const canAdministerCompany = hasPermission('can_administer_companies');
export const canAdministerAgencies = hasPermission('can_administer_agencies');
export const canManageCompanyUsers = hasPermission('can_manage_company_users');
export const canViewEvents = hasPermission('can_view_events');
export const canManageEventCompletion = hasPermission('can_manage_event_completion');
export const canManageEventPreData = hasPermission('can_manage_pre_event_data');
export const canChaseEvent = hasPermission('can_chase_event');
export const canCancelEvent = hasPermission('can_cancel_event');

export const canCreateHighlyConfidentialRfp = hasPermission('can_create_highly_confidential_rfp');
export const canViewHighlyConfidentialRfp = hasPermission('can_view_highly_confidential_rfp');

// Corporate specific permissions
export const canAccessCorporateLocations = hasPermission('can_access_locations');
export const canAccessCorporateCalendar = hasPermission('can_access_corporate_calendar');
export const canAccessCorporateDocuments = hasPermission('can_access_documents');
export const canAccessEdgeTeamUpdates = hasPermission('can_access_edge_team_updates');

export const isInternal = anyPass([
  isRoleSuperAdmin,
  isRoleTrinityEditor,
  isRoleTrinity,
  isRoleEdgeAdmin,
  isRoleTrinityUser
]);

/**
 * Action Permissions
 * auth:Object -> Boolean
 */

export const canDeleteVenue = isRoleSuperAdminOrTrinityEditor;
export const canSetVenueLevel = isRoleSuperAdminOrTrinityEditor;
export const canEditVenueName = isRoleSuperAdminOrTrinityEditor;
export const canRecalculateVenueScore = isRoleSuperAdminOrTrinityEditor;


export const canSeeVenuesAdminArea = anyPass([isRoleSuperAdminOrTrinityEditor, canCreateVenue, canEditVenue]);


