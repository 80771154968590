import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { Input, Textarea, Toggle } from "../../Components/Form";
import { additionalDataCapture as v } from "./validate";
import { Field, FieldArray } from "redux-form";
import { connect } from "react-redux";
import { token } from "../../modules/auth";
import { LiveSearchSimple } from "../../Components/Form/LiveSearch/LiveSearchSimple";
import { createCompany } from "../../redux/actions";
import { ButtonPrimary } from "../../Components/Button";
import * as ROUTES from "../../Router/corporate_routes";
import RepeatableLinkedEnquiries from "../../Components/Rfp/RepeatableLinkedEnquiries";
import Warning from "../../Components/Warning";

const mapStateToProps = state => ({
  token: token(state),
});

const mapDispatchToProps = {
  createCompany,
};

const decisionMakingProcessPlaceholder = `Who is the decision maker?
<br/>              
What is the most important factor for making a decision?
<br/>         
Would you require a site visit?<br/><br/>`;

const probingQuestionsPlaceholder = `What is the previous history of the event?
<br/>
What is the purpose / objective?
<br/>
Who is attending?
<br/>
How often is this event held?`;

const AgencySpecificEnquiryData = ({
                                     setShowNewCompany,
                                     setShowNewUser,
                                     setCompany,
                                     company,
                                     setClientName,
                                     addCompany,
                                     addClient,
                                     client,
                                     setNewCompanyModal,
                                     setNewUserModal,
                                     formValues,
                                     shouldClearSearches = false,
                                     setShouldClearSearches,
                                     editEnquiry = false,
                                     superAgencyCode,
                                     isSuperAgent,
                                     change,
                                     enquiry,
                                     token,
                                     showNulledMaxDelegates,
                                   }) => {
  const [companyResultCount, setCompanyResultCount] = useState(false);
  const [selectedLinkedEnquiries, setSelectedLinkedEnquiries] = useState([]);
  const [linkedEnquiries, setLinkedEnquiries] = useState();
  useEffect(() => {
    addCompany();
    addClient();
    if (shouldClearSearches) {
//       setCompany(null)
//       setClientName(null)
      setShouldClearSearches(false);
    }
  }, [company, client, shouldClearSearches]);

  useEffect(() => {
    if (enquiry && !enquiry?.is_parent_enquiry) {
      getLinkedEnquiries();
    }
  }, []);

  let companySearch = window.API + ROUTES.API.COMPANIES.SEARCH;
  if (superAgencyCode) {
    companySearch = companySearch + "?code=" + superAgencyCode;
  }

  const clearSearchFields = () => {
    setCompany(null);
    setClientName(null);
  };

  const handleLinkedEnquiriesChange = linkedEnquiry => {
    if (!selectedLinkedEnquiries.includes(linkedEnquiry)) {
      setSelectedLinkedEnquiries(prevEnquiries => [...prevEnquiries, linkedEnquiry]);
    }
  };

  const getLinkedEnquiries = async () => {
    setLinkedEnquiries();
    await axios.get(`${window.API}/rfp/enquiry/${enquiry.id}/linked-enquiries`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(({ data }) => {
        setLinkedEnquiries(data);
      })
      .catch(error => {
        setLinkedEnquiries();
        console.error(error);
      });
  };

  return (
    <>
      <div className="row">
        <div className="col col-w-1/3">
          {showNulledMaxDelegates ? (
            <Warning className={"my-2"}>
              <strong>
                Sorry - you've chosen Roche and we've removed the max delegate number you entered on Step 1.
              </strong>
            </Warning>
          ) : null}
          {!editEnquiry && (
            <LiveSearchSimple
              auth
              label="Search Company"
              getText={result => result.name}
              url={companySearch}
              onChange={setCompany}
              onNoResults={result => setShowNewCompany(result)}
              onClear={() => clearSearchFields()}
              setResultCount={setCompanyResultCount}
              shouldClearSearches={shouldClearSearches}
              validate={v.company_id}
            />
          )}
          {editEnquiry && company && (
            <p>
              <strong>{company.name}</strong>
            </p>
          )}
        </div>
        <div className="hidden">
          <Field
            name="company_id"
            component={Input}
          />
        </div>
        {!company && companyResultCount === 0 && (
          <div className="col col-w-1/4">
            <div className="ml-4 mt-5">
              <ButtonPrimary onClick={() => setNewCompanyModal(true)}>
                Create a new Company
              </ButtonPrimary>
            </div>
          </div>
        )}
      </div>
      {company && (
        <div className="row">
          <div className="col col-w-1/3">
            <LiveSearchSimple
              auth
              label="Search Users"
              getText={result => result.first_name + " " + result.surname + ": " + result.email}
              url={`${window.API}/users?companyUser=1&companyId=${company.id}`}
              onChange={setClientName}
              query="companyUser"
              onNoResults={result => setShowNewUser(result)}
              onClear={() => setClientName(null)}
              shouldClearSearches={shouldClearSearches}
              placeholder={client && client.first_name + " " + client.surname + ": " + client.email}
              validate={v.client_id}
            />
            <div className="hidden">
              <Field
                name="client_id"
                component={Input}
                validate={v.client_id}
              />
            </div>
          </div>
          {!client && company.sso_method === null ? <div className="col col-w-1/4">
            <div className="ml-4 mt-5">
              <ButtonPrimary onClick={() => setNewUserModal(true)}>
                Create a new User
              </ButtonPrimary>
            </div>
          </div> : null}
        </div>
      )}
      {isSuperAgent ? (
        <div className="row mb-2">
          <div className="col col-w-1/2">
            <Field
              label="Decision Making Process"
              name="decision_making_process"
              component={Textarea}
              rows={6}
              subTextHint={decisionMakingProcessPlaceholder}
            />
          </div>
          <div className="col col-w-1/2">
            <Field
              label="Probing Questions"
              name="probing_questions"
              component={Textarea}
              rows={6}
              subTextHint={probingQuestionsPlaceholder}
            />
          </div>
        </div>
      ) : null}
      <div className="row">
        <div className="col col-w-1/2">
          <Field
            name="share_with_venues"
            label="Share client name with the Venues?"
            component={Toggle}
            validate={v.share_with_venues}
            wrapperClassName="inline mb-8 xl:w-1/2"
          />
        </div>
      </div>
      <div className="row mt-10">
        <div className="col col-w-1/4">
          <Field
            name="estimated_value"
            label="Estimated Value"
            component={Input}
            validate={v.estimated_value}
            wrapperClassName="w-full mb-8"
          />
        </div>
        <div className="col col-w-1/4">
          <Field
            name="estimated_commission"
            label="Estimated Commission"
            component={Input}
            validate={v.estimated_commission}
            wrapperClassName="w-full mb-8"
          />
        </div>
      </div>
      {company ? (
        <div className="pb-4">
          <label className="mb-1 block font-normal font-heading text-sm uppercase">Linked Enquiries</label>
          {editEnquiry && enquiry && enquiry.is_parent_enquiry ? (
            <>
              <div className="row">
                <div className="col col-w-1/3">
                  <LiveSearchSimple
                    auth
                    multi
                    // label="Linked Enquiries"
                    wrapperClassName="flex flex-col"
                    multiSearchClass="max-w-full"
                    multiResultsClass="hidden"
                    getText={item => `${item.event_name} (RefID: ${item.ref_id})`}
                    url={`${window.API}/rfp/enquiry/quick/search?companyId=${company.id}`}
                    onChange={(item) => handleLinkedEnquiriesChange(item)}
                  />
                </div>
              </div>
              <div className="mb-4">
                <div className="col col-w-full p-4">
                  <FieldArray
                    name="linked_enquiries"
                    props={{ change }}
                    values={formValues}
                    selectedLinkedEnquiries={selectedLinkedEnquiries}
                    component={RepeatableLinkedEnquiries}
                  />
                </div>
              </div>
            </>
          ) : null}
          {!editEnquiry ? (
            <>
              <div className="row">
                <div className="col col-w-1/3">
                  <LiveSearchSimple
                    auth
                    multi
                    wrapperClassName="flex flex-col"
                    multiSearchClass="max-w-full"
                    multiResultsClass="hidden"
                    getText={item => `${item.event_name} (RefID: ${item.ref_id})`}
                    url={`${window.API}/rfp/enquiry/quick/search?companyId=${company.id}`}
                    onChange={(item) => handleLinkedEnquiriesChange(item)}
                  />
                </div>
              </div>
              <div className="mb-4">
                <div className="col col-w-full p-4">
                  <FieldArray
                    name="linked_enquiries"
                    props={{ change }}
                    values={formValues}
                    selectedLinkedEnquiries={selectedLinkedEnquiries}
                    component={RepeatableLinkedEnquiries}
                  />
                </div>
              </div>
            </>
          ) : null}
          {linkedEnquiries && linkedEnquiries.map(linkedEnquiry => {
            return <div className="row mb-4">
              <div className="col col-w-1/3">
                <div className="bg-light-grey p-4">
                  <div className="flex ml-4 justify-center items-center">
                    <div className="col">
                      ID: {linkedEnquiry.id}
                    </div>
                    <div className="col">
                      Ref ID: {linkedEnquiry.ref_id}
                    </div>
                  </div>
                </div>
              </div>
            </div>;
          })}
        </div>
      ) : null}
    </>
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AgencySpecificEnquiryData);
