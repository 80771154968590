// this could be refactored a little to share more from AS

import React from "react";
import { func, object } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import debounce from "lodash/debounce";
import axios from "axios";
import {
  reduxForm,
  Field,
  getFormValues,
  change,
  initialize,
  destroy,
} from "redux-form";
import { getWidth } from "redux-window";
import { Input, Select, Multiselect, Toggle } from "Components/Form";
import Results from "Components/Search/Results";
import Loading from "Components/Loading";
import Location from "Components/Search/Location";
import SearchWidgets from "Components/Search/Widgets";
import search from "./search";
import AdvancedSearchButton from "./AdvancedSearchButton";
import BecomeALicensedVenue from "./BecomeALicensedVenue";
import { FaUsers, FaBed, FaStar, FaTimes, FaFilter } from "react-icons/fa";
import {isLoggedIn, isAgency, isAgencyApproved} from "../../modules/auth";
import {hasLicensedVenues} from "../../modules/permissions";
import qs from "query-string";
import results from "Components/Search/Results";

const form = "simple-search";
const defaultState = {
  results: [],
  count: 0,
  loading: false,
  error: null,
};

class SearchForm extends React.Component {
  static propTypes = {
    destroy: func.isRequired,
    change: func.isRequired,
    values: object.isRequired,
  };

  state = {
    categories: [],
    results: [],
    loading: false,
    error: undefined,
    count: 0,
    filtersVisible: false,
  };

  componentDidMount() {
    this.props.search(this.props.history, this.stateSetter);
    axios
      .get(`${window.API}/venue-categories`)
      .then(r => {
        this.setState({
          categories: r.data.map(item => ({
            label: item.name,
            value: item.id,
          })),
        });
      })
      .catch(err => {});
  }

  reset = () => {
    this.props.destroy(form);
    this.setState({
      ...defaultState,
    });
  };

  stateSetter = {
    loading: () => this.setState({ loading: true, results: [] }),
    error: (
      error = "Sorry, there was an error when searching. Please check the fields and try again."
    ) => this.setState({ loading: false, error }),
    clear: () => this.setState({ ...defaultState }),
    results: (results, count) =>
      this.setState({
        results,
        count,
        loading: false,
        error: false,
      }),
  };

  searchOnBlur = () => {
    this.props.search(this.props.history, this.stateSetter);
  };

  searchOnChange = debounce((e, v, pv, name) => {
    this.props.search(this.props.history, this.stateSetter, {
      commission: name === "agencyRates" ? v : 0,
      [name]:
        name === "venueCategoryId" && v !== null
          ? v.map(option => option.value)
          : v,
    });
  }, 500);

  submit = () => {
    // nowt
  };

  resultsHasDestinations = () => {
    const { results } = this.state;
    return !!results && results.some(result => result.destination_city);
  };

  sortByOptions() {
    return [
      {
        text: "Sort by Default",
        value: "ContentUpdate"
      },
      {
        text: "Sort by Distance",
        value: "Distance",
      },
      {
        text: "Sort by Capacity",
        value: "Capacity",
      },
      this.resultsHasDestinations()
        ? { text: "Sort by Destination", value: "Destination" }
        : null,
      {
        text: "Sort by Bedrooms",
        value: "Bedrooms",
      },
      {
        text: "Sort by Meeting Rooms",
        value: "Meeting rooms",
      },
      {
        text: "Sort by Nearest Airport",
        value: "Nearest airport",
      },
    ].filter(Boolean);
  }

  toggleFiltersVisible = () => {
    this.setState({ filtersVisible: !this.state.filtersVisible });
  };

  getNoResultsMessage = () =>
  {
    const {
     location,
     name
    } = qs.parse(this.props.location.search);
   let loading = this.state.loading;
    if(!location ||  loading || !this.props.values.location ){
      return '';
    }

   return `No results found for ${location ? 'location ' + location : ''}${location && name ? ' and for' : ''}${name ? ' name ' + name : ''}`
  }
  render() {
    return (
      <>
        <form
          onSubmit={this.props.handleSubmit(this.submit)}
          autoComplete="off"
          className="sticky z-10"
          style={{ top: this.props.stickyOffset }}
        >
          <div
            className="container py-8 flex justify-center items-center bg-cover bg-center bg-no-repeat shadow"
            style={{ backgroundImage: "url('/img/search-bg.jpg')" }}
          >
            <div
              style={{ backgroundColor: "rgba(0,0,0,.6)" }}
              className="p-5 relative w-full md:w-2/3 lg:w-1/2"
            >
              <div className="row">
                <div className="w-full flex flex-wrap relative">
                  <div className="flex flex-col col col-w-full xl:col-w-1/2">
                    <p className="text-white uppercase font-normal mb-1 text-xs">
                      Where is your event?
                    </p>
                    <Location
                      id="location-search"
                      formId={form}
                      icon="/img/map-marker.svg"
                      label="Where is your event?"
                      labelClassName="sr-only"
                      name="location"
                      fieldClassName="w-full mb-0"
                      wrapperClassName=""
                      onPlaceFound={(lat, lng, name) => {
                        this.props.search(this.props.history, this.stateSetter);
                      }}
                      placeholder="Search by place"
                    />
                  </div>
                  {/* <div className="rounded-50 bg-edge-blue p-2 absolute-center hidden xl:block">
                    <span className="text-white font-normal text-xs">OR</span>
                  </div> */}
                  <div className="flex flex-col col col-w-full xl:col-w-1/2">
                    <p className="text-white uppercase font-normal mb-1 text-xs">
                      Search by name
                    </p>
                    <Field
                      id="venue-search"
                      label="Search by name"
                      name="name"
                      labelClassName="sr-only"
                      fieldClassName="w-full mb-0"
                      wrapperClassName=""
                      component={Input}
                      onChange={this.searchOnChange}
                      placeholder="Start typing the name"
                    />
                  </div>
                </div>
                <div className="col col-w-full justify-between flex">
                  <AdvancedSearchButton />
                  <div
                    className="pointer uppercase font-bold text-white self-end text-xs flex"
                    onClick={() => {
                      this.reset();
                    }}
                  >
                    Clear all
                    <FaTimes className="text-white ml-2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container w-full bg-white p-4 flex flex-wrap md:flex-no-wrap items-center justify-between sticky z-30">
            <span className="font-normal text-base hidden lg:block">
              Refine your search
            </span>
            <div className="lg:mx-4 w-full lg:mb-4 block lg:hidden flex justify-between flex justify-center">
              <div
                className="flex items-center"
                onClick={this.toggleFiltersVisible}
              >
                <FaFilter className="mr-2 text-2xl" />
                <p className="uppercase font-bold m-0 pointer">
                  {this.state.filtersVisible ? "Close filters" : "Open filters"}
                </p>
              </div>
              <Field
                name="sortBy"
                wrapperClassName="w-48"
                noPlaceholder
                options={this.sortByOptions()}
                labelClassName="sr-only"
                label="Sort by"
                placeholder="Sort by..."
                selectClasses="uppercase"
                component={Select}
                onChange={this.searchOnChange}
              />
            </div>
            {(this.props.desktop || this.state.filtersVisible) && (
              <>
                <div className="px-4 w-1/3 md:px-3 md:w-1/6 mb-4 md:mb-0">
                  <p className="uppercase text-grey-md font-bold text-xs mb-1">
                    Max delegates
                  </p>
                  <div className="flex items-center">
                    <div>
                      <FaUsers className="mr-2 text-2xl hidden md:block" />
                    </div>
                    <Field
                      label="Max delegates"
                      name="capacity"
                      autoComplete="off"
                      labelClassName="sr-only"
                      wrapperClassName="flex"
                      component={Input}
                      onChange={this.searchOnChange}
                    />
                  </div>
                </div>
                <div className="px-4 w-1/3 md:px-3 md:w-1/6 mb-4 md:mb-0">
                  <p className="uppercase text-grey-md font-bold text-xs mb-1">
                    Bedrooms
                  </p>
                  <div className="flex items-center">
                    <div>
                      <FaBed className="mr-2 text-2xl hidden md:block" />
                    </div>
                    <Field
                      label="Bedrooms"
                      name="total_bedrooms"
                      labelClassName="sr-only"
                      wrapperClassName="flex"
                      autoComplete="off"
                      component={Input}
                      onChange={this.searchOnChange}
                    />
                  </div>
                </div>
                <div className="px-4 w-1/3 md:px-3 md:w-1/6 mb-4 md:mb-0">
                  <p className="uppercase text-grey-md font-bold text-xs mb-1">
                    Star rating
                  </p>
                  <div className="flex items-center">
                    <FaStar className="mr-2 text-2xl hidden md:block" />
                    <Field
                      name="star_rating"
                      label="STAR RATING"
                      placeholder="Select"
                      labelClassName="sr-only"
                      wrapperClassName="flex justify-center w-full"
                      component={Select}
                      options={["3", "4", { value: "5", text: "5+" }]}
                      onChange={this.searchOnChange}
                    />
                  </div>
                </div>
                <div className="mx-4 w-full md:w-1/6 mb-4 md:mb-0">
                  <p className="uppercase text-grey-md font-bold text-xs mb-1">
                    Venue type
                  </p>
                  <div className="flex items-center">
                    <Field
                      name="types"
                      label="Venue Type"
                      labelClassName="sr-only"
                      component={Multiselect}
                      placeholder="Select"
                      wrapperClassName="flex w-full"
                      options={this.state.categories}
                      onChange={this.searchOnChange}
                    />
                  </div>
                </div>
                {/*<div className="mx-4 w-full md:w-1/6">*/}
                {/*  <p className="uppercase text-grey-md font-bold text-xs mb-1">*/}
                {/*    Brand*/}
                {/*  </p>*/}
                {/*  <div className="flex items-center">*/}
                {/*    <Field*/}
                {/*      name="brand"*/}
                {/*      label="Brand"*/}
                {/*      labelClassName="sr-only"*/}
                {/*      component={Input}*/}
                {/*      fieldClassName="w-full mb-0"*/}
                {/*      wrapperClassName="flex w-full"*/}
                {/*      onChange={this.searchOnChange}*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*</div>*/}
                {(this.props.isAgency && this.props.isAgencyApproved) && (
                  <div className="mx-4 w-full md:w-1/6">
                    <p className="uppercase text-grey-md font-bold text-xs mb-1">
                      Only agency offers
                    </p>
                    <div className="flex items-center">
                      <Field
                        name="agencyRates"
                        labelClassName="sr-only"
                        component={Toggle}
                        fieldClassName="w-full mb-0"
                        wrapperClassName="flex w-full"
                        onChange={this.searchOnChange}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </form>
        <div className="instantSearchResultsContainer container bg-grey py-5">
          <div className="row">
            <div className="col col-w-full lg:col-w-3/4">
              {this.state.loading ? <Loading large /> : null}
              {this.state.error ? (
                <p className="md:mt-8 text-warning">{this.state.error}</p>
              ) : null}
              {this.state.count ? (
                <div>
                  <div className="flex flex-wrap justify-between">
                    <h1>
                      Showing {this.state.count} venues in{" "}
                      {this.props.values.location}
                    </h1>
                    <Field
                      name="sortBy"
                      wrapperClassName="w-48 hidden md:block"
                      noPlaceholder
                      options={this.sortByOptions()}
                      labelClassName="sr-only"
                      label="Sort by"
                      placeholder="Sort by..."
                      selectClasses="uppercase"
                      component={Select}
                      onChange={this.searchOnChange}
                    />
                  </div>
                  {this.props.desktop && !this.props.isLoggedIn &&
                  <BecomeALicensedVenue
                      headerText="Want to add your venue?"
                      mainMessage={<span>Add your venue for free by <strong>registering online</strong></span>}
                      linkToURL="/venues"
                      buttonText="Register Now"
                  />}
                  {this.props.desktop && this.props.isLoggedIn && !this.props.hasLicensedVenues && <BecomeALicensedVenue />}

                  <div>
                    <div className="w-full" id="results">
                      <Results
                        type="simple"
                        results={this.state.results}
                        loading={this.state.loading}
                        error={this.state.error}
                      />
                    </div>
                  </div>
                </div>
              ) : <p className={'text-center font-bold text-xl'}> {this.getNoResultsMessage()} </p>}
            </div>
            <div
              className="col col-w-full lg:col-w-1/4 fixed pin-b md:relative bg-white md:bg-transparent"
              style={!this.props.desktop ? { padding: 0 } : {}}
            >
              <div className="sticky" style={{ top: 100 }}>
                <SearchWidgets
                  results={this.state.results}
                  form="simple-search-shortlist"
                  searchForm="simple-search"
                  search={() =>
                    this.props.search(this.props.history, this.stateSetter)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(
  reduxForm({
    form,
    destroyOnUnmount: false,
    initialValues: {
//      sortBy: "Distance",
      exhibition: 0,
      radius: 1609.344 * 15,
      types: [],
      sortBy: "Capacity",
    },
  })(
    connect(
      state => ({
        values: getFormValues(form)(state) || {},
        desktop: getWidth(state) > 991,
        stickyOffset: getWidth(state) > 1199 ? -196 : -275,
        isAgency: isAgency(state),
        isAgencyApproved: isAgencyApproved(state),
        isLoggedIn: isLoggedIn(state),
        hasLicensedVenues: hasLicensedVenues(state.auth),
      }),
      { search, change, initialize, destroy }
    )(SearchForm)
  )
);
